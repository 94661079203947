import React, { useState, useEffect, useRef, Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Appservice from '../service/Appservice';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressBar } from 'primereact/progressbar';

export class FormPetition extends Component {
    constructor() {
        super();
        this.state = {
            selectedPetition: null,
            liste_petition: [],
            liste_petition_filtre:[],
            liste_com: [],
            id_com: null,
            modif_petition: false,
            showindicator: false,
            visible_petition: false,
            globalFilter: '',
            visible_dlg_petition: false,
            lib_pet: '',
            desc_pet: '',
            target_pet: 100000,
        };
        this.edit_petition = this.edit_petition.bind(this);
        this.appservice = new Appservice();
    }

    componentDidMount() {
        this.get_liste_petition();
        this.get_liste_commission();
    }

    get_liste_commission() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_commission().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.id_com, label: x.lib_com }
                });
                this.setState({ liste_com: liste });
            }
        });
    }

    get_liste_petition() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_petition().then(data => this.setState({ liste_petition: data,liste_petition_filtre: data, showindicator: false }));
    }

    annuler_petition() {
        this.setState({ lib_pet: '' });
        this.setState({ desc_pet: '' });
        this.setState({ target_pet: 200000 });
        this.setState({ modif_petition: false });
    }

    enreg_petition() {
        if (this.state.id_com == undefined || this.state.id_com == "" || this.state.desc_pet == "" || this.state.lib_pet == "" || this.state.target_pet == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir le libellé et le contenu de la petitionion' });
        } else {
            let data = this.state.selectedPetition;
            data.lib_pet = this.state.lib_pet;
            data.desc_pet = this.state.desc_pet;
            data.target_pet = this.state.target_pet;
            data.id_com = this.state.id_com.value;
            this.appservice.enreg_petition(data).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    this.get_liste_petition();
                }
            });
        }
    }

    filtrer_pet() {
        let liste = this.state.liste_petition;
        let filtre=this.state.globalFilter.toLowerCase();
        if (filtre !== '' && filtre !== undefined) {
            liste = this.state.liste_petition.filter(function (el) {
                let val=el.lib_pet+' '+el.etat_pet+' '+el.nom_pet+' '+el.tel_pet;
                val=val.toLowerCase();
                return val.includes(filtre);
            });
        }
        this.setState({ liste_petition_filtre: liste });
    }


    edit_petition(data) {
        if (data != null && data != undefined) {
            this.setState({ lib_pet: data.lib_pet });
            this.setState({ id_com: data.id_com });
            this.setState({ desc_pet: data.desc_pet });
            this.setState({ target_pet: data.target_pet });
            this.setState({ modif_petition: true });
            this.setState({ selectedPetition: data });
            this.setState({ visible_dlg_petition: true });
        }
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-input-icon-right">
                    <InputText style={{ width: 200 }} type="text" placeholder="Rechercher" onChange={(e) => this.setState({globalFilter: e.target.value },()=>{
                        this.filtrer_pet();
                    })}/>
                    <i className="pi pi-search" />
                </span>
            </React.Fragment>
        )
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    update_etat_petition(data) {
        if (data != null && data != undefined) {
            if (data.etat_pet == 'En attente') {
                data.etat_pet = 'Validé';
            } else if (data.etat_pet == 'Validé') {
                data.etat_pet = 'Invalidé';
            } else if (data.etat_pet == 'Invalidé') {
                data.etat_pet = 'Validé';
            }
            this.appservice.enreg_petition(data, 'VAL').then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    this.get_liste_petition();
                }
            });
        }
    }

    resultat(rep) {
        if (rep.error == false) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.message });
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
        }
    }

    render() {

        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">

                        <Toast ref={(el) => this.toast = el} />
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_petition_filtre} selection={this.state.selectedPetition}
                            rowHover paginator rows={20} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            onSelectionChange={(e) => this.setState({ selectedPetition: e.value })}
                            emptyMessage="No data found" selectionMode="single" onRowClick={e => this.setState({ selectedPetition: e.value })}
                            style={{ width: '100%' }}
                            onRowSelect={e => this.setState({ selectedPetition: e.value })}>
                            <Column field="date_pet" header="Date" style={{ width: '8%' }} sortable />
                            <Column field="lib_pet" header="Désignation" style={{ width: '30%' }} sortable />
                            <Column field="target_pet" header="Nb cible" style={{ width: '9%' }} sortable />
                            <Column field="end_pet" header="Fin" style={{ width: '8%' }} sortable />
                            <Column field="etat_pet" header="Etat" style={{ width: '8%' }} sortable />
                            <Column field="nom_pet" header="Demandeur" body={(rowData, column) => {
                                return <div>
                                    <span style={{ fontWeight: 'bold' }}>{rowData.nom_pet}</span><br />
                                    <span>{rowData.tel_pet}</span>
                                </div>
                            }} style={{ width: '15%' }} sortable />
                            <Column field="nb_sign" header="Signature" style={{ width: '14%' }} body={(rowData, column) => {
                                return <ProgressBar value={rowData.nb_sign} displayValueTemplate={(value, column) => {
                                    return (
                                        <React.Fragment>
                                            {value}/<b>{rowData.target_pet}</b>
                                        </React.Fragment>
                                    );
                                }} />
                            }} sortable />
                            <Column body={(rowData, column) => {
                                let cl = "p-button-warning";
                                if (rowData.etat_pet == 'Validé') {
                                    cl = "p-button-success";
                                } else if (rowData.etat_pet == 'Invalidé') {
                                    cl = "p-button-danger";
                                }
                                return <div className="p-grid" style={{ width: 120 }}>
                                    <div className="p-col"><Button onClick={(e) => this.edit_petition(rowData)} className="p-button-info" style={{ width: 30, height: 30 }} icon="pi pi-pencil" /></div>
                                    <div className="p-col"><Button onClick={(e) => this.update_etat_petition(rowData)} className={cl} style={{ width: 30, height: 30, marginLeft: -15 }} icon="pi pi-check" /></div>
                                </div>
                            }} style={{ width: '8%' }} header="Action" />
                        </DataTable>

                        <Dialog visible={this.state.visible_dlg_petition} style={{ width: '450px' }} header="Reponse petitionion" modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_petition: false })}>
                            <div className="p-field">
                                <label htmlFor="name">Désignation pétition *</label>
                                <InputText id="name" value={this.state.lib_pet} onChange={(e) => this.setState({ lib_pet: e.target.value })} required autoFocus />
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Commission *</label>
                                <Dropdown value={this.state.id_com} options={this.state.liste_com} onChange={(e) => this.setState({ id_com: e.target.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' />
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Contenu petitionion *</label>
                                <InputTextarea id="name" value={this.state.desc_pet} onChange={(e) => this.setState({ desc_pet: e.target.value })} rows={5} cols={20} />
                            </div>
                            <center>
                                <>
                                    <Button label="Annuler" icon="pi pi-times" className="p-button-text" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_petition: false })} />
                                    <Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_petition()} style={{ width: 120 }} />
                                </>
                                <br/>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                        </Dialog>

                    </div>
                </div>
            </div>
        );
    }
}
