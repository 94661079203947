import React, { useState, useEffect, useRef, Component } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { ProgressSpinner } from 'primereact/progressspinner';
import Appservice from '../service/Appservice';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';

const ordersChartOptions = {
    legend: {
        display: true,
    },
    responsive: true,
    hover: {
        mode: 'index',
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    min: 0,
                    max: 20
                }
            }
        ]
    }
};
const petitionChart = {
    labels: [],
    datasets: [
        {
            label: 'Pétition',
            data: [],
            backgroundColor: ['rgba(100, 181, 246, 0.2)'],
            borderColor: ['#64B5F6'],
            borderWidth: 3,
            fill: true
        }
    ]
};

export const Dashboard = () => {
    const toast = useRef(null);
    const [liste_quest_jour, setListe_quest_jour] = useState(null);
    const [liste_quest_jour_filtre, setListe_quest_jour_filtre] = useState(null);

    const [liste_depute, setListe_depute] = useState(null);
    const [id_depute, setId_depute] = useState(null);
    const [liste_stat, setListe_stat] = useState([]);
    const [showindicator, setShowindicator] = useState(false);

    const [selectedQuest, setSelectedQuest] = useState(null);
    const [visible_dlg_quest, setVisible_dlg_quest] = useState(false);

    const [nb_quest_total, setNb_quest_total] = useState(0);
    const [nb_quest_rep, setNb_quest_rep] = useState(0);
    const [nb_pet_encours, setNb_pet_encours] = useState(0);
    const [nb_pet_adopte, setNb_pet_adopte] = useState(0);
    const [nb_quest_jour, setNb_quest_jour] = useState(0);
    const [nb_quest_val_jour, setNb_quest_val_jour] = useState(0);
    const [nb_sign_total, setNb_sign_total] = useState(0);
    const [nb_commentaire, setNb_commentaire] = useState(0);

    const [contenu_quest, setContenu_quest] = useState(null);
    const [rep_quest, setRep_quest] = useState(null);

    const appservice = new Appservice();

    useEffect(() => {
        refreshDashboard();
    }, []);

    const menuRef = useRef(null);
    const chartRef = useRef(null);

    const toogleDlg = () => {
        setVisible_dlg_quest(true);
    }

    const resultat = (rep) => {
        if (rep !== undefined) {
            if (rep.error == false) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: rep.message });
                setShowindicator(true);
                appservice.getDashboard('findQuestJourListe').then((data) => {
                    setListe_quest_jour(data);
                    setListe_quest_jour_filtre(data);
                });
                appservice.getDashboard('findQuestStat').then(data => {
                    if (data !== null && data !== undefined && data[0] !== undefined) {
                        setNb_quest_total(data[0].nb_quest_total);
                        setNb_quest_rep(data[0].nb_quest_rep);
                        setNb_quest_jour(data[0].nb_quest_jour);
                        setNb_quest_val_jour(data[0].nb_quest_val_jour);
                    }
                });
            } else {
                toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
            }
        }
        else {
            toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
        }
        setShowindicator(false);
    }

    const refreshDashboard = () => {
        setShowindicator(true);
        appservice.getDashboard('findQuestJourListe').then((data) => {
            setListe_quest_jour(data);
            setListe_quest_jour_filtre(data);
        });

        appservice.get_liste_depute().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.id_depute, label: x.nom_depute }
                });
                setListe_depute([{ value: 'Tous', label: 'Tous les députés' }].concat(liste));
            }
        });

        appservice.getDashboard('findQuestStat').then(data => {
            if (data !== null && data !== undefined && data[0] !== undefined) {
                setNb_quest_total(data[0].nb_quest_total);
                setNb_quest_rep(data[0].nb_quest_rep);
                setNb_quest_jour(data[0].nb_quest_jour);
                setNb_quest_val_jour(data[0].nb_quest_val_jour);
            }
        });

        appservice.getDashboard('findSignatureStatNb').then(data => {
            if (data !== null && data !== undefined && data[0] !== undefined) {
                setNb_sign_total(data[0].nb_sign_total);
                setNb_commentaire(data[0].nb_commentaire);
            }
        });

        appservice.getDashboard('findPetitionStatNb').then(data => {
            if (data !== null && data !== undefined && data[0] !== undefined) {
                setNb_pet_encours(data[0].nb_pet_val);
            }
        });

        appservice.getDashboard('findPetitionStatAdope').then(data => {
            if (data !== null && data !== undefined && data[0] !== undefined) {
                setNb_pet_adopte(data[0].nb_pet_adopte);
            }
        });

        appservice.getDashboard('findPetitionStatTotalByMonth').then(data => {
            setShowindicator(false);
            if (data !== null && data !== undefined) {
                setListe_stat(data);
                let liste_mois = data.map(x => {
                    return x.mois
                });
                let liste_value = data.map(x => {
                    return x.nb_pet
                });
                petitionChart.labels = liste_mois;
                petitionChart.datasets[0].data = liste_value;
                petitionChart.datasets[0].label = "Total pétition";
                petitionChart.datasets[0].borderColor = "#BBDEFB";
                petitionChart.datasets[0].backgroundColor = "rgba(100, 181, 246, 0.2)";
                chartRef.current.refresh();
            }
        });
    }

    const searshQuestion = (event) => {
        setId_depute(event.value);
        let id = event.value;
        let liste = liste_quest_jour;
        if (id !== 'Tous' && id !== undefined) {
            liste = liste_quest_jour.filter(function (el) {
                return el.id_depute == id;
            });
        }
        setListe_quest_jour_filtre(liste);
    };

    const changeDataset = (event) => {
        let index = parseInt(event.currentTarget.getAttribute('data-index'));
        let url = '';
        if (index == 0) {
            url = 'findPetitionStatTotalByMonth';
        } else if (index == 1) {
            url = 'findPetitionStatTermineByMonth';
        } else if (index == 2) {
            url = 'findPetitionStatECByMonth';
        } else if (index == 3) {
            url = 'findPetitionStatAdope';
        }
        let label = event.currentTarget.getAttribute('data-label');
        let border = event.currentTarget.getAttribute('data-stroke');
        let backcolor = event.currentTarget.getAttribute('data-fill');
        setShowindicator(true);
        appservice.getDashboard(url).then(data => {
            setShowindicator(false);
            if (data !== null && data !== undefined) {
                let liste_mois = data.map(x => {
                    return x.mois
                });
                let liste_value = data.map(x => {
                    return x.nb_pet
                });
                petitionChart.labels = liste_mois;
                petitionChart.datasets[0].data = liste_value;
                petitionChart.datasets[0].label = label;
                petitionChart.datasets[0].borderColor = border;
                petitionChart.datasets[0].backgroundColor = backcolor;
                chartRef.current.refresh();
            }
        });
    };

    const refreshDataset = (event) => {
        chartRef.current.refresh(event);
    };

    const onOrderTabClick = (event) => {
        changeDataset(event);
        refreshDataset(event);
    };

    const edit_quest = (data) => {
        if (data != null && data != undefined) {
            setRep_quest(data.rep_quest);
            setContenu_quest(data.contenu_quest);
            setSelectedQuest(data);
            setVisible_dlg_quest(true);
        }
    }

    const update_etat_quest = (data) => {
        if (data != null && data != undefined) {
            if (data.etat_quest == 'En attente') {
                data.etat_quest = 'Validé';
            } else if (data.etat_quest == 'Validé') {
                data.etat_quest = 'Invalidé';
            } else if (data.etat_quest == 'Invalidé') {
                data.etat_quest = 'Validé';
            }
            setShowindicator(true);
            appservice.update_question(data, 'VAL').then(reponse => {
                if (reponse != undefined && reponse != null) {
                    resultat(reponse);
                }
            });
        }
    }

    const enreg_quest = () => {
        if (contenu_quest == "") {
            toast.current.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir le contenu de la question' });
        } else {
            let data = selectedQuest;
            data.rep_quest = rep_quest;
            data.contenu_quest = contenu_quest;
            appservice.update_question(data, 'REP').then(reponse => {
                if (reponse != undefined && reponse != null) {
                    resultat(reponse);
                }
            });
        }

    }

    return (
        <div className="layout-dashboard">
            <div className="p-grid">
                <div className="p-col-12 p-md-6 p-xl-3">
                    <div className="card no-gutter widget-overview-box widget-overview-box-1">
                        <span className="overview-icon">
                            <i className="pi pi-question-circle"></i>
                        </span>
                        <span className="overview-title">Questions</span>
                        <div className="p-grid overview-detail">
                            <div className="p-col-6">
                                <div className="overview-number">{appservice.formaterValueSep(nb_quest_total)}</div>
                                <div className="overview-subtext">Totale</div>
                            </div>
                            <div className="p-col-6">
                                <div className="overview-number">{appservice.formaterValueSep(nb_quest_rep)}</div>
                                <div className="overview-subtext">Repondues</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-6 p-xl-3">
                    <div className="card no-gutter widget-overview-box widget-overview-box-2">
                        <span className="overview-icon">
                            <i className="pi pi-user-edit"></i>
                        </span>
                        <span className="overview-title">Pétitions</span>
                        <div className="p-grid overview-detail">
                            <div className="p-col-6">
                                <div className="overview-number">{appservice.formaterValueSep(nb_pet_encours)}</div>
                                <div className="overview-subtext">Validées</div>
                            </div>
                            <div className="p-col-6">
                                <div className="overview-number">{appservice.formaterValueSep(nb_pet_adopte)}</div>
                                <div className="overview-subtext">Réussies</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-6 p-xl-3">
                    <div className="card no-gutter widget-overview-box widget-overview-box-3">
                        <span className="overview-icon">
                            <i className="pi pi-question-circle"></i>
                        </span>
                        <span className="overview-title">Questions du jour</span>
                        <div className="p-grid overview-detail">
                            <div className="p-col-6">
                                <div className="overview-number">{appservice.formaterValueSep(nb_quest_jour)}</div>
                                <div className="overview-subtext">Total</div>
                            </div>
                            <div className="p-col-6">
                                <div className="overview-number">{appservice.formaterValueSep(nb_quest_val_jour)}</div>
                                <div className="overview-subtext">Validés</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-6 p-xl-3">
                    <div className="card no-gutter widget-overview-box widget-overview-box-4">
                        <span className="overview-icon">
                            <i className="pi pi-comment"></i>
                        </span>
                        <span className="overview-title">Signatures</span>
                        <div className="p-grid overview-detail">
                            <div className="p-col-6">
                                <div className="overview-number">{appservice.formaterValueSep(nb_sign_total)}</div>
                                <div className="overview-subtext">Total</div>
                            </div>
                            <div className="p-col-6">
                                <div className="overview-number">{appservice.formaterValueSep(nb_commentaire)}</div>
                                <div className="overview-subtext">Commentaires</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-xl-6">
                    <div className="card no-gutter orders" style={{ minHeight: 510 }}>
                        <div className="card-header">
                            <h4>Statistiques des pétitions</h4>
                            {showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            {/* <Menu id="orders-button" popup model={items} ref={(el) => { this.menuRef = el; }} appendTo={document.body}></Menu> */}
                            {/* <Button type="button" icon="pi pi-print" className="p-button-text p-button-secondary" onClick={this.menuToggle} aria-controls="orders-button" aria-haspopup="true"></Button> */}
                        </div>

                        <div className="p-grid">
                            <div className="p-col-12">
                                <div id="order-tabs-container" className="p-grid order-tabs">
                                    <div className="order-tab order-tab-new p-col-6 p-md-3" onClick={onOrderTabClick}
                                        data-label="Total pétition" data-index="0" data-stroke="#BBDEFB" data-fill="rgba(100, 181, 246, 0.2)">
                                        <i className="pi pi-plus-circle"></i>
                                        <span className="order-label">Total</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-new.svg" alt="order" />
                                    </div>
                                    <div className="order-tab order-tab-completed p-col-6 p-md-3" onClick={onOrderTabClick}
                                        data-label="Pétitions terminées" data-index="1" data-stroke="#C5CAE9" data-fill="rgba(121, 134, 203, 0.2)">
                                        <i className="pi pi-check-circle"></i>
                                        <span className="order-label">Terminées</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-completed.svg" alt="order" />
                                    </div>
                                    <div className="order-tab order-tab-refunded p-col-6 p-md-3" onClick={onOrderTabClick}
                                        data-label="Pétitions en cours" data-index="2" data-stroke="#B2DFDB" data-fill="rgba(224, 242, 241, 0.5)">
                                        <i className="pi pi-refresh"></i>
                                        <span className="order-label">En cours</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-refunded.svg" alt="order" />
                                    </div>
                                    <div className="order-tab order-tab-cancelled p-col-6 p-md-3" onClick={onOrderTabClick}
                                        data-label="Pétions adoptées" data-index="3" data-stroke="#B2EBF2" data-fill="rgba(224, 247, 250, 0.5)">
                                        <i className="pi pi-times-circle"></i>
                                        <span className="order-label">Réussies</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-cancelled.svg" alt="order" />
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="overview-chart">
                                    <Chart ref={chartRef} type="line" data={petitionChart} options={ordersChartOptions} id="order-chart"></Chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-xl-6">
                    <div className="card" style={{ minHeight: 510 }}>
                        <div className="card-header">
                            <h4>Questions du jour</h4>
                            <Dropdown options={liste_depute} value={id_depute} placeholder="Députés" optionLabel="label" filter={true} filterBy='label' filterMatchMode='contains' onChange={searshQuestion} className="dashbard-demo-dropdown" style={{ width: 260 }}></Dropdown>
                        </div>

                        <Toast ref={toast} />
                        <Dialog visible={visible_dlg_quest} style={{ width: '450px' }} header="Reponse question" modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_quest: false })}>

                            <div className="p-field">
                                <label htmlFor="name">Question *</label>
                                <InputTextarea id="name" value={contenu_quest} onChange={(e) => setContenu_quest(e.target.value)} rows={5} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Reponse *</label>
                                <InputTextarea id="name" value={rep_quest} onChange={(e) => setRep_quest(e.target.value)} rows={5} />
                            </div>
                            <center>
                                <>
                                    <Button label="Annuler" icon="pi pi-times" className="p-button-text" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_quest: false })} />
                                    <Button label="Valider" icon="pi pi-check" onClick={enreg_quest} style={{ width: 120 }} />
                                </>
                                <br />
                                {showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                        </Dialog>


                        <DataTable dataKey="id_quest" value={liste_quest_jour_filtre} paginator rows={4} className="p-datatable-products"
                            selection={selectedQuest} onSelectionChange={(e) => setSelectedQuest(e.value)} selectionMode="single">
                            <Column field="id_quest" header="N°" style={{ width: '14%' }} sortable />
                            <Column field="contenu_quest" header="Question" style={{ width: '68%' }} body={(rowData, column) => {
                                let contenu = rowData.contenu_quest;
                                if (contenu !== null && contenu !== undefined) {
                                    contenu = contenu.substring(0, 80) + '... ?';
                                }
                                return <div>
                                    <span style={{ fontWeight: 'bold', color: '#be342d' }}>{rowData.nom_depute}</span>
                                    <br />
                                    <span style={{ fontWeight: 'bold', color: '#414D5A', fontFamily: 'Arial' }}>{contenu}</span>
                                </div>
                            }} sortable />

                            <Column field="etat_quest" header="Etat" style={{ width: '20%' }} body={(rowData, column) => {
                                let cl = "p-button-warning";
                                if (rowData.etat_quest == 'Validé') {
                                    cl = "p-button-success";
                                } else if (rowData.etat_quest == 'Invalidé') {
                                    cl = "p-button-danger";
                                }
                                return <div className="p-grid" style={{ width: 120 }}>
                                    <div className="p-col"><Button onClick={(e) => edit_quest(rowData)} className="p-button-info" style={{ width: 30, height: 30 }} icon="pi pi-pencil" /></div>
                                    <div className="p-col"><Button onClick={(e) => update_etat_quest(rowData)} className={cl} style={{ width: 30, height: 30, marginLeft: -15 }} icon="pi pi-check" /></div>
                                </div>

                            }} sortable />
                            {/* <Column bodyStyle={{ textAlign: 'center', justifyContent: 'center' }} body={() => <Button type="button" icon="pi pi-pencil"></Button>} style={{ width: '10%' }} /> */}
                        </DataTable>
                    </div>
                </div>

            </div>
        </div>
    );

}
