import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';

import AppTopBar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppMenu from './AppMenu';
import AppSearch from './AppSearch';
import AppRightMenu from './AppRightMenu';

import { FormDepute } from './components/FormDepute';
import { FormQuest } from './components/FormQuestion';
import { FormPetition } from './components/FormPetition';
import { FormSignature } from './components/FormSignature';
import { FormUsers } from './components/FormUsers';
import { FormCommission } from './components/FormCommission';
import { Dashboard } from './components/Dashboard';
import { FormChronogramme } from './components/FormChronogramme';

import Appservice from './service/Appservice';
import PrimeReact from 'primereact/utils';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

const App = () => {

    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, setMenuMode] = useState('static');
    const [colorScheme, setColorScheme] = useState('light');
    const [menuTheme, setMenuTheme] = useState('layout-sidebar-darkgray');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);

    let menuClick = false;
    let searchClick = false;
    let userMenuClick = false;
    let notificationMenuClick = false;
    let rightMenuClick = false;
    let configClick = false;

    const appservice = new Appservice();
    var menu =[];
    var profil_suer='';
    var userconnected=appservice.get_userconnected();
    
    if(userconnected!==null && userconnected!==undefined){
        profil_suer=userconnected.profil_user;
    }
    if(profil_suer=='Administrateur'){
        menu = [
            {
                label: "Favorites", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard" },
                    { label: "Députés", icon: "pi pi-fw pi-users", to: "/depute" },
                    { label: "Gestion des questions", icon: "pi pi-fw pi-question-circle", to: "/gestion_question" },
                    { label: "Chronogramme", icon: "pi pi-fw pi-calendar-plus", to: "/chronogramme" },
                    // { label: "Questions", icon: "pi pi-fw pi-question-circle", to: "/questions" },
                ]
            },
            { separator: true },
            {
                label: "Pétitions", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Liste pétition", icon: "pi pi-fw pi-check-square", to: "/petition" },
                    { label: "Signatures", icon: "pi pi-fw pi-inbox", to: "/signatures" },
                    { label: "Statistiques", icon: "pi pi-fw pi-chart-bar", to: "/dashboard" },
                    // { label: "Pétitions", icon: "pi pi-fw pi-sign-in", to: "/petitions" },
                    { label: "Commissions", icon: "pi pi-fw pi-share-alt", to: "/commissions" },
                ]
            },
            { separator: true },
    
            {
                label: "Compte", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Utilisateurs", icon: "pi pi-fw pi-users", to: "/users" },
                    // { label: "Mon profil", icon: "pi pi-fw pi-id-card", to: "/" },
                    {
                        label: "Déconnexion", icon: "pi pi-fw pi-sign-out", command: () => {
                            localStorage.removeItem('userconnected');
                            window.location = "#/login";
                        }
                    },
                ]
            },
    
        ];
    }if(profil_suer=='Modérateur'){
        menu = [
            {
                label: "Favorites", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard" },
                    { label: "Gestion des questions", icon: "pi pi-fw pi-question-circle", to: "/gestion_question" },
                    // { label: "Questions", icon: "pi pi-fw pi-question-circle", to: "/questions" },
                ]
            },
            { separator: true },
            {
                label: "Pétitions", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Liste pétition", icon: "pi pi-fw pi-check-square", to: "/petition" },
                    { label: "Signatures", icon: "pi pi-fw pi-inbox", to: "/signatures" },
                    { label: "Statistiques", icon: "pi pi-fw pi-chart-bar", to: "/dashboard" },
                    // { label: "Pétitions", icon: "pi pi-fw pi-sign-in", to: "/petitions" },
                ]
            },
            { separator: true },
    
            {
                label: "Compte", icon: "pi pi-fw pi-home",
                items: [
                    // { label: "Mon profil", icon: "pi pi-fw pi-id-card", to: "/" },
                    {
                        label: "Déconnexion", icon: "pi pi-fw pi-sign-out", command: () => {
                            localStorage.removeItem('userconnected');
                            window.location = "#/login";
                        }
                    },
                ]
            },
    
        ];
    }
    

    const routers = [
        { path: '/dashboard', component: Dashboard, exact: true, meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }] } },

        { path: '/depute', component: FormDepute, meta: { breadcrumb: [{ parent: 'Députés', label: 'Députés' }] } },
        { path: '/gestion_question', component: FormQuest, meta: { breadcrumb: [{ parent: 'Questions', label: 'Questions aux élus' }] } },
        { path: '/petition', component: FormPetition, meta: { breadcrumb: [{ parent: 'Petition', label: 'Liste pétition' }] } },
        { path: '/signatures', component: FormSignature, meta: { breadcrumb: [{ parent: 'Signatures', label: 'Signature' }] } },
        { path: '/users', component: FormUsers, meta: { breadcrumb: [{ parent: 'Utilisateurs', label: 'Utilisateurs' }] } },
        { path: '/commissions', component: FormCommission, meta: { breadcrumb: [{ parent: 'Commissions', label: 'Commissions' }] } },
        { path: '/chronogramme', component: FormChronogramme, meta: { breadcrumb: [{ parent: 'Chronogramme', label: 'Chronogramme' }] } },


    ];

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        }
        else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!userMenuClick) {
            setTopbarUserMenuActive(false);
        }

        if (!notificationMenuClick) {
            setTopbarNotificationMenuActive(false);
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (!menuClick) {
            if (isSlim()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        searchClick = false;
        configClick = false;
        userMenuClick = false;
        rightMenuClick = false;
        notificationMenuClick = false;
        menuClick = false;
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setTopbarNotificationMenuActive(false);
        setRightMenuActive(false);

        if (isOverlay()) {
            setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
        }
        else {
            setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
        }

        event.preventDefault();
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive(prevMenuActive => !prevMenuActive);
    };

    const onMenuThemeChange = (name) => {
        setMenuTheme('layout-sidebar-' + name);
    };

    const onMenuModeChange = (e) => {
        setMenuMode(e.value);
    };

    const onColorSchemeChange = (e) => {
        setColorScheme(e.value);
    };

    const onTopbarUserMenuButtonClick = (event) => {
        userMenuClick = true;
        setTopbarUserMenuActive(prevTopbarUserMenuActive => !prevTopbarUserMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarNotificationMenuButtonClick = (event) => {
        notificationMenuClick = true;
        setTopbarNotificationMenuActive(prevTopbarNotificationMenuActive => !prevTopbarNotificationMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const toggleSearch = () => {
        setSearchActive(prevSearchActive => !prevSearchActive);
        searchClick = true;
    };

    const onSearchClick = () => {
        searchClick = true;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuButtonClick = (event) => {
        rightMenuClick = true;
        setRightMenuActive(prevRightMenuActive => !prevRightMenuActive);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive(prevConfigActive => !prevConfigActive);
        configClick = true;
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        }
        else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        }
        else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const isSlim = () => {
        return menuMode === "slim";
    };

    const isOverlay = () => {
        return menuMode === "overlay";
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const containerClassName = classNames('layout-wrapper',
        {
            'layout-overlay': menuMode === "overlay",
            'layout-static': menuMode === "static",
            'layout-slim': menuMode === "slim",
            'layout-sidebar-dim': colorScheme === "dim",
            'layout-sidebar-dark': colorScheme === "dark",
            'layout-overlay-active': overlayMenuActive,
            'layout-mobile-active': staticMenuMobileActive,
            'layout-static-inactive': staticMenuDesktopInactive && menuMode === "static",
            'p-input-filled': inputStyle === "filled",
            'p-ripple-disabled': !ripple,
        },
        colorScheme === 'light' ? menuTheme : '');

    return (
        <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
            <div className="layout-content-wrapper">
                <AppTopBar routers={routers} topbarNotificationMenuActive={topbarNotificationMenuActive} topbarUserMenuActive={topbarUserMenuActive} onMenuButtonClick={onMenuButtonClick} onSearchClick={toggleSearch}
                    onTopbarNotification={onTopbarNotificationMenuButtonClick} onTopbarUserMenu={onTopbarUserMenuButtonClick} onRightMenuClick={onRightMenuButtonClick} onRightMenuButtonClick={onRightMenuButtonClick}></AppTopBar>

                <div className="layout-content">
                    {
                        routers.map((router, index) => {
                            if (router.exact) {
                                return <Route key={`router${index}`} path={router.path} exact component={router.component} />
                            }

                            return <Route key={`router${index}`} path={router.path} component={router.component} />
                        })
                    }
                </div>

                <AppFooter />
            </div>

            <AppMenu model={menu} menuMode={menuMode} active={menuActive} mobileMenuActive={staticMenuMobileActive} onMenuClick={onMenuClick} onMenuitemClick={onMenuitemClick} onRootMenuitemClick={onRootMenuitemClick}></AppMenu>

            <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick}></AppRightMenu>

            {/* <AppConfig configActive={configActive} menuMode={menuMode} onMenuModeChange={onMenuModeChange} menuTheme={menuTheme} onMenuThemeChange={onMenuThemeChange}
                colorScheme={colorScheme} onColorSchemeChange={onColorSchemeChange} onConfigClick={onConfigClick} onConfigButtonClick={onConfigButtonClick}
                rippleActive={ripple} onRippleChange={onRippleChange} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}></AppConfig>
            */}
            <AppSearch searchActive={searchActive} onSearchClick={onSearchClick} onSearchHide={onSearchHide} />

            <div className="layout-mask modal-in"></div>
        </div>
    );
}

export default App;
