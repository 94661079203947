import React, { useState, useEffect, useRef, Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Appservice from '../service/Appservice';
import { Dropdown } from 'primereact/dropdown';

export class FormUsers extends Component {
    constructor() {
        super();
        this.state = {
            selectedUser: null,
            email_user: '',
            nom_user: '',
            login_user: '',
            profil_user: {value: 'Modérateur',label: 'Modérateur'},
            liste_users: [],
            id_user: null,
            actif_user: null,
            tel_user: '',
            modif_user: false,
            visible: false,
            visible_dlg_user: false,
        };
        this.edit_user = this.edit_user.bind(this);
        this.conf_delete_user = this.conf_delete_user.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.appservice = new Appservice();
    }


   
    componentDidMount() {
        this.get_liste_users();
    }

    get_liste_users() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_users().then(data => this.setState({liste_users: data, showindicator: false }));
    }

    annuler() {
        this.setState({ nom_user: '' });
        this.setState({ email_user: '' });
        this.setState({ tel_user: '' });
        this.setState({ login_user: '' });
        this.setState({ modif_user: false });
    }

    edit_user(data) {
        if(data!==null && data!==undefined){
            this.setState({ nom_user: data.nom_user});
            this.setState({ email_user: data.email_user});
            this.setState({ tel_user: data.tel_user });
            this.setState({ login_user: data.login_user });
            this.setState({ profil_user: data.profil_user });
            this.setState({ actif_user: data.actif_user });
            this.setState({ modif_user: true });
            this.setState({ visible_dlg_user: true });
        }
    }

    enreg_user() {
        if (this.state.nom_user == "" || this.state.tel_user == "" || this.state.email_user == "" || this.state.profil_user == "" || this.state.actif_user == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            if (this.state.modif_user == false) {
                let data =
                {
                    'nom_user': this.state.nom_user,
                    'email_user': this.state.email_user,
                    'tel_user': this.state.tel_user,
                    'actif_user': this.state.actif_user,
                    'login_user': this.state.login_user,
                    'profil_user': this.state.profil_user
                }
                this.appservice.enreg_user(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        this.resultat(reponse);
                        if (reponse.error == false) {
                            this.get_liste_users();
                        }
                    }
                });
            } else {
                let data = this.state.selectedUser;
                data.nom_user = this.state.nom_user;
                data.email_user = this.state.email_user;
                data.tel_user = this.state.tel_user;
                data.profil_user = this.state.profil_user;
                data.actif_user = this.state.actif_user;
                this.appservice.enreg_user(data).then(reponse => {
                    {
                        if (reponse != undefined && reponse != null) {
                            this.resultat(reponse);
                            if (reponse.error == false) {
                                this.get_liste_users();
                            }
                        }
                    }
                });
            }
        }
    }



    conf_delete_user(data) {
        this.setState({ visible: true });
        this.setState({ selectedUser: data });
    }

    toogleDlg() {
        this.setState({ visible_dlg_user: !this.state.visible_dlg_user });
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nouveau" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.toogleDlg} />
                <Button label="Modifier" disabled={this.state.selectedUser == null} icon="pi pi-pencil" className="p-button-info" onClick={(e) => this.edit_user(this.state.selectedUser)} />
            </React.Fragment>
        )
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    resultat(rep) {
        if (rep.error == false) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.message });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 110 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_user(rowData)} className="p-button-info" style={{ width: 30, height: 30 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_user(rowData)} className="p-button-danger" style={{ width: 30, height: 30,marginLeft:-10 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_user() {
        if (this.state.selectedUser == null || this.state.selectedUser == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.appservice.delete_user(this.state.selectedUser).then(reponse => {
                this.resultat(reponse);
                if (reponse.error == false) {
                    this.get_liste_users();
                }
            });
        }
    }


    render() {
       
        const deleteDialogFooter = (
            <>
                <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={() => {
                    this.setState({ visible: false });
                }} />
                <Button label="Oui" icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_user();
                }} />
            </>
        );

        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">

                        <Toast ref={(el) => this.toast = el} />
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_users}
                            scrollHeight="300px" scrollable={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            dataKey="id_user" rowHover selection={this.state.selectedUser} onSelectionChange={(e) => this.setState({ selectedUser: e.value })}
                            emptyMessage="No data found" selectionMode="single" onRowClick={e => this.setState({ selectedUser: e.value }, () => {
                            })}
                            onRowSelect={e => this.setState({ selectedUser: e.value })}>
                            <Column field="nom_user" header="Nom"  style={{ width: '24%' }} />
                            <Column field="login_user" header="Login"  style={{ width: '10%' }} />
                            <Column field="email_user" header="Email" style={{ width: '20%' }} />
                            <Column field="tel_user" header="Téléphone" style={{ width: '15%' }} />
                            <Column field="profil_user" header="Profil" style={{ width: '15%' }} />
                            <Column field="actif_user" header="Actif" style={{ width: '8%' }} />
                            <Column body={this.actionTemplate.bind(this)} style={{ width: '8%' }} header="Action" />
                        </DataTable>


                        <Dialog visible={this.state.visible_dlg_user} style={{ width: '450px' }} header={this.state.modif_user == true ? "Modification" : "Nouveau utilisateur"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_user: false })}>
                            <div className="p-field">
                                <label htmlFor="name">Login *</label>
                                <InputText id="name" value={this.state.login_user} onChange={(e) => this.setState({ login_user: e.target.value })} required autoFocus />
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Nom *</label>
                                <InputText id="name" value={this.state.nom_user} onChange={(e) => this.setState({ nom_user: e.target.value })} required autoFocus />
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Téléphone *</label>
                                <InputText id="name" value={this.state.tel_user} onChange={(e) => this.setState({ tel_user: e.target.value })} required autoFocus />
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Email *</label>
                                <InputText id="name" value={this.state.email_user} onChange={(e) => this.setState({ email_user: e.target.value })} required autoFocus />
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Profil *</label>
                                <Dropdown value={this.state.profil_user} options={[
                                    { label: 'Administrateur', value: 'Administrateur' },
                                    { label: 'Modérateur', value: 'Modérateur' }
                                ]} onChange={(e) => this.setState({ profil_user: e.target.value })} autoWidth={false} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Actif *</label>
                                <Dropdown value={this.state.actif_user} options={[
                                    { label: 'Oui', value: 'Oui' },
                                    { label: 'Non', value: 'Non' }
                                ]} onChange={(e) => this.setState({ actif_user: e.target.value })} autoWidth={false} />
                            </div>

                            <center>
                                <>
                                    <Button label="Annuler" icon="pi pi-times" className="p-button-text" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_user: false })} />
                                    <Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_user()} style={{ width: 120 }} />
                                </>
                                <br/>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                        </Dialog>

                        <Dialog visible={this.state.visible} style={{ width: '450px' }} header="Confirmation" modal footer={deleteDialogFooter} onHide={(e) => this.setState({ visible: false })}>
                            <div className="p-d-flex p-ai-center p-jc-center">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedUser && <span>Voulez-vous vraiment supprimer <b>{this.state.selectedUser.nom_user}</b>?</span>}
                            </div>
                        </Dialog>

                      
                    </div>
                </div>
            </div>
        );
    }
}
