import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import Appservice from '../service/Appservice';

export class Login extends Component {
    constructor() {
        super();
        this.state = {
            showindicator: false,
            login: '',
            pwd: '',
            msg: '',
        };
        this.appservice = new Appservice();
    }

    handleKeyPress = e => {
		if (e.keyCode === 13) {
			this.identification();
		}
	}



	identification() {
		if (this.state.login == '' || this.state.pwd == '') {
			this.setState({ msg: 'Veuilllez saisir le login et le mot de passe' });
		} else {
			this.setState({ showindicator: true });
			this.appservice.get_identification(this.state.login, this.state.pwd).then(data => {
				if (data != null && data != undefined && data != '' && data.length>0) {
					if (data[0].actif_user === 'Oui') {
						localStorage.setItem('userconnected', JSON.stringify(data[0]));
						window.location = "#/dashboard";
						this.setState({ showindicator: false });
					} else {
						this.setState({ showindicator: false });
						this.setState({ msg: "Vous n'etes pas autorisés à vous connecter, merci de contacter l'administrateur " });
					}
				} else {
					this.setState({ showindicator: false });
					this.setState({ msg: 'Login ou mot de passe incorrects' });
				}
			});
		}
	}

 
    render() {
        return (
            <div className="login-body">
                <div className="login-wrapper">
                    <div className="login-panel" >
                        <img src="assets/layout/images/logo-white.png" className="logo" alt="diamond-layout" style={{ height: 140, width: 120 }} />
                        <div className="login-form" style={{marginTop: 20}}>
                            <h2>Connexion</h2>
                            <h4>Pour vous connecter utiliser vos accès préalablement créés et fournis par l'adminsitrateur</h4>
                            <InputText placeholder="Votre login" onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ login: e.target.value })} value={this.state.login} style={{width: '90%'}}/>
                            <Password feedback={false} placeholder="Votre mot de passe" onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ pwd: e.target.value })} value={this.state.pwd} style={{width: '90%'}}/>
                            <Button label="Connexion" icon="pi pi-sign-in" type="button" style={{width: 150}}  onClick={(e) => this.identification()}></Button>
                            <br/>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', marginTop: '10px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            <br/>
							<label style={{ color: 'red', marginTop: 10,fontWeight: 'bold' }} >{this.state.msg}</label>
                        </div>
                    </div>
                    <div className="login-image">
                        <div className="login-image-content">
                            <h1>PORTAIL ANG</h1>
                            <h3>
                                Modules de gestion des pétitions et des questions aux élus
					        </h3>
                        </div>
                        <div className="image-footer">
                            <p>Nous suivre sur les réseaux sociaux</p>
                            <div className="icons">
                                <i className="pi pi-facebook"></i>
                                <i className="pi pi-twitter"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
