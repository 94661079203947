import React, { useState, useEffect, useRef, Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Appservice from '../service/Appservice';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import 'moment/locale/fr';
import moment from 'moment';
import { Panel } from 'primereact/panel';

export class FormChronogramme extends Component {
    constructor() {
        super();
        this.state = {
            selectedChronogramme: null,
            title: '',
            cmt_chrono: '',
            start: new Date(),
            end: new Date(),
            liste_chronogramme: [],
            modif: false,
            visible: false,
            visible_dlg_chrono: false
        };
        this.edit_chronogramme = this.edit_chronogramme.bind(this);
        this.conf_delete_chronogramme = this.conf_delete_chronogramme.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.appservice = new Appservice();
    }

    componentDidMount() {
        this.get_liste_chronogramme();
    }

    get_liste_chronogramme() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_chronogramme().then(data => this.setState({ liste_chronogramme: data, showindicator: false }));
    }

    annuler() {
        this.setState({ start: null });
        this.setState({ end: null });
        this.setState({ title: '' });
        this.setState({ cmt_chrono: '' });
        this.setState({ modif: false });
    }

    edit_chronogramme(data) {
        if (data !== null && data !== undefined) {
            this.setState({ cmt_chrono: data.cmt_chrono });
            this.setState({ start: new Date(moment(data.start, 'YYYY-MM-DD H:mm:ss').format('YYYY-MM-DD H:mm:ss')) });
            this.setState({ end: new Date(moment(data.end, 'YYYY-MM-DD H:mm:ss').format('YYYY-MM-DD H:mm:ss')) });
            this.setState({ title: data.title });
            this.setState({ modif: true });
            this.setState({ visible_dlg_chrono: true });
        }
    }

    enreg_chrono() {
        if (this.state.title == "" || this.state.start == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                let data =
                {
                    'title': this.state.title,
                    'cmt_chrono': this.state.cmt_chrono,
                    'start': moment(this.state.start, 'DD/MM/YYYY H:mm:ss').format('YYYY-MM-DD H:mm:ss'),
                    'end': moment(this.state.end, 'DD/MM/YYYY H:mm:ss').format('YYYY-MM-DD H:mm:ss')
                }
                this.setState({ showindicator: true });
                this.appservice.enreg_chronogramme(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        this.resultat(reponse);
                        if (reponse.error == false) {
                            this.get_liste_chronogramme();
                        }
                    }
                });
            } else {
                let data = this.state.selectedChronogramme;
                data.title = this.state.title;
                data.cmt_chrono = this.state.cmt_chrono;
                data.start = moment(this.state.start, 'DD/MM/YYYY H:mm:ss').format('YYYY-MM-DD H:mm:ss');
                data.end = moment(this.state.end, 'DD/MM/YYYY H:mm:ss').format('YYYY-MM-DD H:mm:ss');
                this.setState({ showindicator: true });
                this.appservice.enreg_chronogramme(data).then(reponse => {
                    {
                        if (reponse != undefined && reponse != null) {
                            this.resultat(reponse);
                            if (reponse.error == false) {
                                this.get_liste_chronogramme();
                            }
                        }
                    }
                });
            }
        }
    }



    conf_delete_chronogramme(data) {
        this.setState({ visible: true });
        this.setState({ selectedChronogramme: data });
    }

    toogleDlg() {
        this.setState({ visible_dlg_chrono: !this.state.visible_dlg_chrono });
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nouveau" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.toogleDlg} />
                <Button label="Modifier" disabled={this.state.selectedChronogramme == null} icon="pi pi-pencil" className="p-button-info" onClick={(e) => this.edit_chronogramme(this.state.selectedChronogramme)} />
            </React.Fragment>
        )
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    resultat(rep) {
        if (rep.error == false) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.message });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
        }
        this.setState({ showindicator: false });
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 110 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_chronogramme(rowData)} className="p-button-info" style={{ width: 30, height: 30 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_chronogramme(rowData)} className="p-button-danger" style={{ width: 30, height: 30, marginLeft: -10 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_chrono() {
        if (this.state.selectedChronogramme == null || this.state.selectedChronogramme == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.appservice.delete_chronogramme(this.state.selectedChronogramme).then(reponse => {
                this.resultat(reponse);
                if (reponse.error == false) {
                    this.get_liste_chronogramme();
                }
            });
        }
    }


    render() {

        const deleteDialogFooter = (
            <>
                <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={() => {
                    this.setState({ visible: false });
                }} />
                <Button label="Oui" icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_chrono();
                }} />
            </>
        );

        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">

                        <Toast ref={(el) => this.toast = el} />
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_chronogramme}
                            scrollHeight="900px" scrollable={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            dataKey="id" rowHover selection={this.state.selectedChronogramme} onSelectionChange={(e) => this.setState({ selectedChronogramme: e.value })}
                            emptyMessage="No data found" selectionMode="single" onRowClick={e => this.setState({ selectedChronogramme: e.value }, () => {
                            })}
                            onRowSelect={e => this.setState({ selectedChronogramme: e.value })}>
                            <Column field="title" header="Titre" style={{ width: '40%' }} />
                            <Column field="cmt_chrono" header="Commentaire" style={{ width: '30%' }} />
                            <Column field="start" header="Début" style={{ width: '10%' }} />
                            <Column field="end" header="Fin" style={{ width: '10%' }} />
                            <Column body={this.actionTemplate.bind(this)} style={{ width: '10%' }} header="Action" />
                        </DataTable>


                        <Dialog visible={this.state.visible_dlg_chrono} style={{ width: '450px' }} header={this.state.modif == true ? "Modification" : "Nouvelle Commission"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_chrono: false })}>
                            <Panel style={{height: '700px'}} >
                                <div className="p-field">
                                    <label htmlFor="name">Début *</label>
                                    <Calendar value={this.state.start} onChange={(e) => this.setState({ start: e.target.value })} showTime showSeconds dateFormat="dd/mm/yy" />
                                </div>

                                <div className="p-field">
                                    <label htmlFor="name">Fin *</label>
                                    <Calendar value={this.state.end} onChange={(e) => this.setState({ end: e.target.value })} showTime showSeconds dateFormat="dd/mm/yy" />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="name">Titre *</label>
                                    <InputTextarea value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} rows={3} />
                                </div>

                                <div className="p-field">
                                    <label htmlFor="name">Commentaire </label>
                                    <InputTextarea id="name" value={this.state.cmt_chrono} onChange={(e) => this.setState({ cmt_chrono: e.target.value })} rows={5} />
                                </div>
                                <center>
                                    <>
                                        <Button label="Annuler" icon="pi pi-times" className="p-button-text" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_chrono: false })} />
                                        <Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_chrono()} style={{ width: 120 }} />
                                    </>
                                    <br />
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>
                            </Panel>

                        </Dialog>

                        <Dialog visible={this.state.visible} style={{ width: '450px' }} header="Confirmation" modal footer={deleteDialogFooter} onHide={(e) => this.setState({ visible: false })}>
                            <div className="p-d-flex p-ai-center p-jc-center">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedChronogramme && <span>Voulez-vous vraiment supprimer <b>{this.state.selectedChronogramme.title}</b>?</span>}
                            </div>
                        </Dialog>


                    </div>
                </div>
            </div>
        );
    }
}
