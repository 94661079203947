import React, { useState, useEffect, useRef, Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Appservice from '../service/Appservice';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputTextarea } from 'primereact/inputtextarea';

 
export class FormDepute extends Component {
    constructor() {
        super();
        this.state = {
            selectedDepute: null,
            selectedContact: null,
            selectedQuest: null,
            liste_depute: [],
            email_depute: '',
            nom_depute: '',
            scrutin_depute: null,
            liste_user: [],
            id_user: null,
            circonscription_depute: null,
            liste_contact: [],
            liste_question: [],
            modif_depute: false,
            modif_quest: false,
            showindicator: false,
            visible: false,
            visible_contact: false,
            visible_question: false,
            globalFilter: '',
            visible_dlg_depute: false,
            visible_dlg_quest: false,
            lib_quest: '',
            contenu_quest: '',
            rep_quest: '',
        };
        this.toogleDlgDepute = this.toogleDlgDepute.bind(this);
        this.edit_depute = this.edit_depute.bind(this);
        this.edit_quest = this.edit_quest.bind(this);

        this.conf_delete_depute = this.conf_delete_depute.bind(this);
        this.conf_delete_contacts = this.conf_delete_contacts.bind(this);
        this.conf_delete_question = this.conf_delete_question.bind(this);

        this.appservice = new Appservice();
    }


    get_liste_users() {
        this.appservice.get_liste_users().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.id_user, label: x.nom_user }
                });
                this.setState({ liste_user: liste });
            }
        });
    }

    componentDidMount() {
        this.get_liste_depute();
        this.get_liste_users();
    }

    get_liste_depute() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_depute().then(data => this.setState({ liste_depute: data, showindicator: false }));
    }

    annuler() {
        this.setState({ nom_depute: '' });
        this.setState({ email_depute: '' });
        this.setState({ circonscription_depute: '' });
        this.setState({ modif_depute: false });
    }

    annuler_quest() {
        this.setState({ lib_quest: '' });
        this.setState({ contenu_quest: '' });
        this.setState({ rep_quest: '' });
        this.setState({ modif_quest: false });
    }

    enreg_depute() {
        if (this.state.nom_depute == "" || this.state.circonscription_depute == "" || this.state.scrutin_depute == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            let data =
            {
                'nom_depute': this.state.nom_depute,
                'email_depute': this.state.email_depute,
                'scrutin_depute': this.state.scrutin_depute,
                'circonscription_depute': this.state.circonscription_depute
            }
            if (this.state.modif_depute == false) {
                this.appservice.enreg_depute(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        this.resultat(reponse);
                        if (reponse.error == false) {
                            this.get_liste_depute();
                        }
                    }
                });
            } else {
                let data = this.state.selectedDepute;
                data.nom_depute = this.state.nom_depute;
                data.email_depute = this.state.email_depute;
                data.scrutin_depute = this.state.scrutin_depute;
                data.circonscription_depute = this.state.circonscription_depute;
                this.appservice.enreg_depute(data).then(reponse => {
                    {
                        if (reponse != undefined && reponse != null) {
                            this.resultat(reponse);
                            if (reponse.error == false) {
                                this.get_liste_depute();
                            }
                        }
                    }
                });
            }
        }
    }


    enreg_contacts() {
        if (this.state.id_user == "" || this.state.id_user == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner le contact à ajouter' });
        } else if (this.state.selectedDepute == null || this.state.selectedDepute == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un député' });
        } else {
            let data =
            {
                'id_depute': this.state.selectedDepute.id_depute,
                'id_user': this.state.id_user,
            }
            this.appservice.enreg_contacts(data).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    this.appservice.get_liste_contacts(this.state.selectedDepute.id_depute).then(data => this.setState({ liste_contact: data, showindicator: false }));
                }
            });
        }
    }


    enreg_quest() {
        if (this.state.contenu_quest == "" || this.state.lib_quest == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir le libellé et le contenu de la question' });
        } else if (this.state.selectedDepute == null || this.state.selectedDepute == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un député' });
        } else {
            let data = this.state.selectedQuest;
            data.rep_quest = this.state.rep_quest;
            data.lib_quest = this.state.lib_quest;
            data.contenu_quest = this.state.contenu_quest;
            this.appservice.update_question(data, 'REP').then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    this.appservice.get_liste_question_by_depute(this.state.selectedDepute.id_depute).then(data => this.setState({ liste_contact: data, showindicator: false }));
                }
            });
        }
    }


    toogleDlgDepute() {
        this.setState({ visible_dlg_depute: !this.state.visible_dlg_depute });
    }

    update_etat_quest(data) {
        if (data != null && data != undefined) {
            if (data.etat_quest == 'En attente') {
                data.etat_quest = 'Validé';
            } else if (data.etat_quest == 'Validé') {
                data.etat_quest = 'Invalidé';
            } else if (data.etat_quest == 'Invalidé') {
                data.etat_quest = 'Validé';
            }
            this.appservice.update_question(data, 'VAL').then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    this.appservice.get_liste_question_by_depute(this.state.selectedDepute.id_depute).then(data => this.setState({ liste_contact: data, showindicator: false }));
                }
            });
        }
    }

    edit_quest(data) {
        if (data != null && data != undefined) {
            this.setState({ lib_quest: data.lib_quest });
            this.setState({ rep_quest: data.rep_quest });
            this.setState({ contenu_quest: data.contenu_quest });
            this.setState({ modif_quest: true });
            this.setState({ selectedQuest: data });
            this.setState({ visible_dlg_quest: true });
        }
    }

    edit_depute(data) {
        if (data != null && data != undefined) {
            this.setState({ nom_depute: data.nom_depute });
            this.setState({ email_depute: data.email_depute });
            this.setState({ circonscription_depute: data.circonscription_depute });
            this.setState({ scrutin_depute: data.scrutin_depute });
            this.setState({ modif_depute: true });
            this.setState({ selectedDepute: data });
            this.setState({ visible_dlg_depute: true });
        }
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    conf_delete_depute(data) {
        this.setState({ visible: true });
        this.setState({ selectedDepute: data });
    }

    conf_delete_contacts(data) {
        this.setState({ visible_contact: true });
        this.setState({ selectedContact: data });
    }

    conf_delete_question(data) {
        this.setState({ visible_question: true });
        this.setState({ selectedQuest: data });
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nouveau" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.toogleDlgDepute} />
                <Button label="Modifier" disabled={this.state.selectedDepute == null} icon="pi pi-pencil" className="p-button-info" onClick={(e) => this.edit_depute(this.state.selectedDepute)} />
            </React.Fragment>
        )
    }

    leftToolbarTemplate_Contact = () => {
        return (
            <React.Fragment>
                <Dropdown value={this.state.id_user} options={this.state.liste_user} placeholder="Sélectioner un contact" onChange={(e) => this.setState({ id_user: e.target.value })} autoWidth={false} style={{ width: '300px' }} filter={true} filterBy='label' filterMatchMode='contains' />
                <Button label="Ajouter" disabled={this.state.id_user == null || this.state.id_user == undefined || this.state.id_user == ''} icon="pi pi-plus" className="p-button-info" onClick={(e) => this.enreg_contacts()} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :  <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }



    resultat(rep) {
        if (rep.error == false) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.message });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 110 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_depute(rowData)} className="p-button-info" style={{ width: 30, height: 30 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_depute(rowData)} className="p-button-danger" style={{ width: 30, height: 30, marginLeft: -10 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_depute() {
        if (this.state.selectedDepute == null || this.state.selectedDepute == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.appservice.delete_depute(this.state.selectedDepute).then(reponse => {
                this.resultat(reponse);
                if (reponse.error == false) {
                    this.get_liste_depute();
                }
            });
        }
    }

    delete_contacts() {
        if (this.state.selectedContact == null || this.state.selectedContact == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.appservice.delete_contacts(this.state.selectedContact).then(data => this.resultat(data));
            this.appservice.get_liste_contacts(this.state.selectedDepute.id_depute).then(data => this.setState({ liste_contact: data }));
        }
    }

    render() {
       const liste_circonscription = this.appservice.get_liste_circonscription();
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">Manage Products</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Search..." />
                </span>
            </div>
        );
        const deleteDeputeDialogFooter = (
            <>
                <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={() => {
                    this.setState({ visible: false });
                }} />
                <Button label="Oui" icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_depute();
                }} />
            </>
        );

        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">

                        <Toast ref={(el) => this.toast = el} />
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_depute}
                            scrollHeight="200px" scrollable={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            dataKey="id_depute" rowHover selection={this.state.selectedDepute} onSelectionChange={(e) => this.setState({ selectedDepute: e.value })}
                            emptyMessage="No data found" selectionMode="single" onRowClick={e => this.setState({ selectedDepute: e.value }, () => {
                                this.appservice.get_liste_contacts(this.state.selectedDepute.id_depute).then(data => this.setState({ liste_contact: data, showindicator: false }));
                                this.appservice.get_liste_question_by_depute(this.state.selectedDepute.id_depute).then(data => this.setState({ liste_question: data, showindicator: false }));
                            })}
                            onRowSelect={e => this.setState({ selectedDepute: e.value })}>
                            <Column field="id_depute" header="ID" sortable style={{ width: '5%' }} />
                            <Column field="nom_depute" header="Nom député" sortable filter={true} filterMatchMode='contains' style={{ width: '30%' }} />
                            <Column field="email_depute" header="Email" sortable filter={true} filterMatchMode='contains' style={{ width: '25%' }} />
                            <Column field="circonscription_depute" header="Circonscription" sortable filter={true} filterMatchMode='contains' style={{ width: '15%' }} />
                            <Column field="scrutin_depute" header="Scrutin" sortable filter style={{ width: '15%' }} />
                            <Column body={this.actionTemplate.bind(this)} style={{ width: '10%' }} header="Action" />
                        </DataTable>
                        <TabView>
                            <TabPanel header="Contacts">
                                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate_Contact} ></Toolbar>
                                <DataTable value={this.state.liste_contact} selection={this.state.selectedContact}
                                    className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    rowHover scrollHeight="200px" scrollable={true} onSelectionChange={(e) => this.setState({ selectedContact: e.value })}
                                    emptyMessage="No data found" selectionMode="single" onRowClick={e => this.setState({ selectedContact: e.value })}
                                    onRowSelect={e => this.setState({ selectedContact: e.value })}>
                                    <Column field="nom_user" header="Nom contact" style={{ width: '25%' }} />
                                    <Column field="email_user" header="Email" style={{ width: '35%' }} />
                                    <Column field="tel_user" header="Téléphone" style={{ width: '12%' }} />
                                    <Column field="profil_user" header="Profil" style={{ width: '15%' }} />
                                    <Column field="actif_user" header="Actif" style={{ width: '6%' }} />
                                    <Column body={(rowData, column) => {
                                        return <Button onClick={(e) => this.conf_delete_contacts(rowData)} className="p-button-danger" style={{ width: 30, height: 30 }} icon="pi pi-times" />
                                    }} style={{ width: '7%' }} header="Action" />
                                </DataTable>


                            </TabPanel>

                            <TabPanel header="Questions">
                                <DataTable value={this.state.liste_question} selection={this.state.selectedQuest}
                                    rowHover paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onSelectionChange={(e) => this.setState({ selectedQuest: e.value })}
                                    emptyMessage="No data found" selectionMode="single" onRowClick={e => this.setState({ selectedQuest: e.value })}
                                    style={{ width: '100%' }}
                                    onRowSelect={e => this.setState({ selectedQuest: e.value })}>
                                    <Column field="date_quest" header="Date" style={{ width: '8%' }} sortable />
                                    <Column field="lib_quest" header="Libellé question" style={{ width: '18%' }} sortable />
                                    <Column field="contenu_quest" header="Contenu question" style={{ width: '20%' }} sortable />
                                    <Column field="etat_quest" header="Etat" style={{ width: '8%' }} sortable />
                                    <Column field="nom_quest" header="Demandeur" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ fontWeight: 'bold' }}>{rowData.nom_quest}</span><br />
                                            <span>{rowData.tel_quest}</span>
                                        </div>
                                    }} style={{ width: '13%' }} sortable />
                                    <Column field="circonscription_quest" header="Circonscription" style={{ width: '14%' }} sortable />
                                    <Column field="nb_like_quest" header="Like" style={{ width: '7%' }} sortable />
                                    <Column field="nb_unlike_quest" header="Unlike" style={{ width: '8%' }} sortable />
                                    <Column body={(rowData, column) => {
                                        let cl = "p-button-warning";
                                        if (rowData.etat_quest == 'Validé') {
                                            cl = "p-button-success";
                                        } else if (rowData.etat_quest == 'Invalidé') {
                                            cl = "p-button-danger";
                                        }
                                        return <div className="p-grid" style={{ width: 120 }}>
                                            <div className="p-col"><Button onClick={(e) => this.edit_quest(rowData)} className="p-button-info" style={{ width: 30, height: 30 }} icon="pi pi-pencil" /></div>
                                            <div className="p-col"><Button onClick={(e) => this.update_etat_quest(rowData)} className={cl} style={{ width: 30, height: 30, marginLeft: -10 }} icon="pi pi-check" /></div>
                                        </div>
                                    }} style={{ width: '8%' }} header="Action" />
                                </DataTable>
                            </TabPanel>

                        </TabView>


                        <Dialog visible={this.state.visible_dlg_quest} style={{ width: '450px' }} header="Reponse question" modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_quest: false })}>
                            <div className="p-field">
                                <label htmlFor="name">Libellé question*</label>
                                <InputText id="name" value={this.state.lib_quest} onChange={(e) => this.setState({ lib_quest: e.target.value })} required autoFocus />
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Contenu question *</label>
                                <InputTextarea id="name" value={this.state.contenu_quest} onChange={(e) => this.setState({ contenu_quest: e.target.value })} rows={5} cols={20} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Reponse *</label>
                                <InputTextarea id="name" value={this.state.rep_quest} onChange={(e) => this.setState({ rep_quest: e.target.value })} rows={5} cols={20} />
                            </div>
                            <center>
                                <>
                                    <Button label="Annuler" icon="pi pi-times" className="p-button-text" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_quest: false })} />
                                    <Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_quest()} style={{ width: 120 }} />
                                </>
                                <br/>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                        </Dialog>



                        <Dialog visible={this.state.visible_dlg_depute} style={{ width: '450px' }} header={this.state.modif_depute == true ? "Modification" : "Nouveau député"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_depute: false })}>
                            <div className="p-field">
                                <label htmlFor="name">Circonscription *</label>
                                <Dropdown options={liste_circonscription} value={this.state.circonscription_depute} optionLabel="label" filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ circonscription_depute: e.target.value })} style={{ width: '100%' }}></Dropdown>
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Nom député*</label>
                                <InputText id="name" value={this.state.nom_depute} onChange={(e) => this.setState({ nom_depute: e.target.value })} required autoFocus />
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Email *</label>
                                <InputText id="name" value={this.state.email_depute} onChange={(e) => this.setState({ email_depute: e.target.value })} required autoFocus />
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Scrutin *</label>
                                <Dropdown value={this.state.scrutin_depute} options={[
                                    { label: 'Uninominal', value: 'Uninominal' },
                                    { label: 'Liste nationale', value: 'Liste nationale' }
                                ]} onChange={(e) => this.setState({ scrutin_depute: e.target.value })} autoWidth={false} />
                            </div>
                            

                            <center>
                                <>
                                    <Button label="Annuler" icon="pi pi-times" className="p-button-text" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_depute: false })} />
                                    <Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_depute()} style={{ width: 120 }} />
                                </>
                                <br/>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                        </Dialog>

                        <Dialog visible={this.state.visible} style={{ width: '450px' }} header="Confirmation" modal footer={deleteDeputeDialogFooter} onHide={(e) => this.setState({ visible: false })}>
                            <div className="p-d-flex p-ai-center p-jc-center">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedDepute && <span>Voulez-vous vraiment supprimer <b>{this.state.selectedDepute.nom_depute}</b>?</span>}
                            </div>
                        </Dialog>

                        <Dialog visible={this.state.visible_contact} style={{ width: '450px' }} header="Confirmation" modal footer={<>
                            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={() => {
                                this.setState({ visible_contact: false });
                            }} />
                            <Button label="Oui" icon="pi pi-check" onClick={() => {
                                this.setState({ visible_contact: false });
                                this.delete_contacts();
                            }} />
                        </>} onHide={(e) => this.setState({ visible_contact: false })}>
                            <div className="p-d-flex p-ai-center p-jc-center">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedContact && <span>Voulez-vous vraiment supprimer <b>{this.state.selectedContact.nom_user}</b>?</span>}
                            </div>
                        </Dialog>


                    </div>
                </div>
            </div>
        );
    }
}
