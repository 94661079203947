import React, { useState, useEffect, useRef, Component } from 'react';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Appservice from '../service/Appservice';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Messages } from 'primereact/messages';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';

export class MyQuestion extends Component {
    constructor() {
        super();
        this.state = {
            liste_question: [],
            liste_question_filtre: [],
            showindicator: false,
            selectedQuest: null,
            id_depute: null,
            liste_depute: [],
            globalFilter: '',
            visible_dlg_quest: false,
            visible_dlg_rep: false,
            lib_quest: '',
            contenu_quest: '',
            nom_quest: '',
            tel_quest: '',
            email_quest: '',
            circonscription_quest: null,
            rep_quest: '',
        };
        this.appservice = new Appservice();
        this.toogleDlg = this.toogleDlg.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.noter = this.noter.bind(this);
    }

    componentDidMount() {
        this.get_liste_question();
        this.get_liste_depute();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({ id_depute: this.props.match.params.id });
        }
    }

    filtrer_quest() {
        let liste = this.state.liste_question;
        let filtre = this.state.globalFilter.toLowerCase();
        if (filtre !== '' && filtre !== undefined) {
            liste = this.state.liste_question.filter(function (el) {
                let val = el.id_depute + ' ' + el.nom_depute + ' ' + el.circonscription_quest;
                val = val.toLowerCase();
                return val.includes(filtre);
            });
        }
        this.setState({ liste_question_filtre: liste });
    }

    get_liste_depute() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_depute().then(data => {
            if (data !== null && data !== undefined && data.length>0) {
                let liste = data.map(x => {
                    return { value: x.id_depute, label: x.nom_depute }
                });
                this.setState({ liste_depute: liste }, () => {
                    let id = this.get_id_depute();
                    if (id !== 'Tous') {
                        let id_dep = this.state.liste_depute.filter(function (el) {
                            return el.value == id;
                        });
                        if (id_dep !== undefined) {
                            if (id_dep.length > 0) {
                                this.setState({ id_depute: id_dep[0] });
                            }
                        }
                    }
                });
            }
        });
    }


    get_liste_question() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_question_by_depute_valides(this.get_id_depute()).then(data => this.setState({ liste_question: data, liste_question_filtre: data, showindicator: false }));
    }

    toogleDlg() {
        if (this.state.id_depute !== null && this.state.id_depute !== undefined) {
            this.setState({ id_depute: this.state.id_depute.value });
        }
        this.setState({ visible_dlg_quest: !this.state.visible_dlg_quest });
    }

    noter(data, action) {
        if (data !== null && data !== undefined) {
            this.setState({ showindicator: true });
            this.appservice.update_question(data, action).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.setState({ showindicator: false });
                    if (reponse != undefined && reponse != null) {
                        if (reponse.error == false) {
                            this.messages.show({ severity: 'success', summary: '', detail: reponse.message, life: 10000 });
                            this.get_liste_question();
                        } else {
                            this.messages.show({ severity: 'error', summary: '', detail: 'Echec système, merci de ressayer plus tard !', life: 10000 });
                        }
                    } else {
                        this.messages.show({ severity: 'error', summary: '', detail: 'Echec système, merci de ressayer plus tard !', life: 10000 });
                    }
                }
            });
        }
    }

    annuler_quest() {
        this.setState({ lib_quest: '' });
        this.setState({ nom_quest: '' });
        this.setState({ tel_quest: '' });
        this.setState({ email_quest: '' });
        this.setState({ contenu_quest: '' });
        this.setState({ rep_quest: '' });
        this.setState({ modif_quest: false });
    }

    enreg_quest() {
        if (this.state.id_depute == undefined || this.state.id_depute == null || this.state.contenu_quest == "" || this.state.tel_quest == "" || this.state.nom_quest == "") {
            this.messages.show({ severity: 'warn', summary: '', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            let data = {
                tel_quest: this.state.tel_quest,
                email_quest: this.state.email_quest,
                nom_quest: this.state.nom_quest,
                contenu_quest: this.state.contenu_quest,
                circonscription_quest: this.state.circonscription_quest,
                id_depute: this.state.id_depute,
            };
            this.setState({ showindicator: true });
            this.appservice.add_question(data).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.setState({ showindicator: false });
                    if (reponse != undefined && reponse != null) {
                        if (reponse.error == false) {
                            this.messages.show({ severity: 'success', summary: '', detail: 'Votre question a été prise en compte et sera repondu dans les plus brefs délais, merci pour votre confiance !', life: 10000 });
                            this.get_liste_question();
                        } else {
                            this.messages.show({ severity: 'error', summary: '', detail: 'Echec système, merci de ressayer plus tard !', life: 10000 });
                        }
                    } else {
                        this.messages.show({ severity: 'error', summary: '', detail: 'Echec système, merci de ressayer plus tard !', life: 10000 });
                    }
                }
            });
        }
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-input-icon-right">
                    <InputText style={{ width: 200 }} type="text" placeholder="Rechercher" onChange={(e) => this.setState({ globalFilter: e.target.value }, () => {
                        this.filtrer_quest();
                    })} />
                    <i className="pi pi-search" />
                </span>
            </React.Fragment>
        )
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Poser une question" icon="pi pi-plus" className="p-button-info" onClick={(e) => this.toogleDlg()} />
            </React.Fragment>
        )
    }

    renderListItem(data) {
        return (
            <div className="p-col-12">
                <div className="p-d-flex p-p-2 ">
                    <label className="p-mr-2" style={{ fontSize: '15px', color: '#000', fontFamily: 'Arial', lineHeight: 1.5 }}>{data.contenu_quest}</label>
                    <div className="p-ml-auto" style={{ fontSize: '15px', color: '#999', fontFamily: 'Arial' }} >{data.date_quest}</div>
                </div>
                <label style={{ fontSize: '15px', color: '#be342d', fontFamily: 'Arial', fontWeight: 'bold', marginLeft: 8 }}>{data.nom_depute}</label>
                <div className="p-d-flex p-p-3">
                    <Button icon="pi pi-thumbs-up" label={data.nb_like_quest} onClick={(e) => this.noter(data, 'LIKE')} className="p-button-rounded p-button-success p-button-text p-mr-2" />
                    <Button type="Button" label={data.nb_unlike_quest} icon="pi pi-thumbs-down" onClick={(e) => this.noter(data, 'UNLIKE')} className="p-button-rounded p-button-danger p-button-text" />
                    <Button icon="pi pi-search" label="Reponse" disabled={data.rep_quest == '' || data.rep_quest == null} onClick={(e) => {
                        this.setState({ rep_quest: data.rep_quest, contenu_quest: data.contenu_quest }, () => {
                            this.setState({ visible_dlg_rep: true });
                        })
                    }} className="p-ml-auto p-button-info" />
                </div>
            </div>
        );
    }

    itemTemplate(data) {
        if (!data) {
            return;
        }
        return this.renderListItem(data);
    }

    get_id_depute() {
        let val = 'Tous';
        if (this.props.match.params.id != null && this.props.match.params.id != undefined) {
            val = this.props.match.params.id;
        }
        return val;
    }

    render() {
        const liste_circonscription = this.appservice.get_liste_circonscription();
        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">

                        {this.state.visible_dlg_quest ?
                            <>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-6">
                                        <label >Votre circonscription *</label>
                                        <Dropdown value={this.state.circonscription_quest} options={liste_circonscription} onChange={(e) => this.setState({ circonscription_quest: e.target.value })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                                    </div>

                                    <div className="p-field p-col-12 p-md-6">
                                        <label >Député(e) concerné(e) *</label>
                                        <Dropdown value={this.state.id_depute} options={this.state.liste_depute} onChange={(e) => this.setState({ id_depute: e.target.value })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                                    </div>

                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="name">Nom et Prénoms * </label>
                                        <InputText id="name" value={this.state.nom_quest} onChange={(e) => this.setState({ nom_quest: e.target.value })} required />
                                    </div>

                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="name">Téléphone *</label>
                                        <InputText id="name" value={this.state.tel_quest} onChange={(e) => this.setState({ tel_quest: e.target.value })} required />
                                    </div>

                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="name">Email </label>
                                        <InputText id="name" value={this.state.email_quest} onChange={(e) => this.setState({ email_quest: e.target.value })} required />
                                    </div>

                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="name">Question *</label>
                                        <InputTextarea id="name" value={this.state.contenu_quest} onChange={(e) => this.setState({ contenu_quest: e.target.value })} rows={2} />
                                    </div>

                                </div>
                                <Messages ref={(el) => this.messages = el}></Messages>
                                <center>
                                    <>
                                        <Button label="Annuler" icon="pi pi-times" className="p-button-text" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_quest: false })} />
                                        <Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_quest()} style={{ width: 120 }} />
                                    </>
                                    <br />
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>
                            </>
                            :
                            <>
                                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                                <Messages ref={(el) => this.messages = el}></Messages>
                                <center>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>
                                {this.state.liste_question_filtre!==undefined ?  <DataView value={this.state.liste_question_filtre} emptyMessage="Aucune question disponible" layout='list' itemTemplate={this.itemTemplate} /> : null}

                               
                            </>
                        }



                        <Dialog visible={this.state.visible_dlg_rep} style={{ width: '450px' }} position='top' header="Reponse" modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_rep: false })}>
                            <div className="p-d-flex p-flex-column">
                                <div className="p-mb-2">Question</div>
                                <div className="p-mb-2"><label style={{ fontSize: 15, color: '#000', fontFamily: 'Arial', lineHeight: 1, fontWeight: 'bold' }}>{this.state.contenu_quest} </label></div>
                                <div>Reponse </div>
                                <div className="p-mb-2"><label style={{ fontSize: 15, color: '#000', fontFamily: 'Arial', lineHeight: 1.5 }}>{this.state.rep_quest} </label></div>
                            </div>

                            <center>
                                <>
                                    <Button label="Fermer" icon="pi pi-times" onClick={(e) => this.setState({ visible_dlg_rep: false })} style={{ width: 120 }} />
                                </>
                            </center>

                        </Dialog>


                    </div>
                </div>
            </div>
        );
    }
}
