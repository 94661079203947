import React, { useState, useEffect, useRef, Component } from 'react';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import Appservice from '../service/Appservice';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Messages } from 'primereact/messages';
import { InputTextarea } from 'primereact/inputtextarea';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import 'moment/locale/fr';
import moment from 'moment';

export class MyPetition extends Component {
    constructor() {
        super();
        this.state = {
            selectedPetition: null,
            liste_petition: [],
            liste_com: [],
            showindicator: false,
            visible_signature: false,
            id_com: null,
            email_sign: '',
            nom_sign: '',
            tel_sign: '',
            cmt_sign: '',

            nom_pet: '',
            tel_pet: '',
            desc_pet: '',
            email_pet: '',
            page: 'liste',
        };
        this.itemTemplate = this.itemTemplate.bind(this);
        this.appservice = new Appservice();
    }

    componentDidMount() {
        this.get_liste_petition();
        this.get_liste_commission();
    }

    get_petition() {
        this.setState({ showindicator: true });
        this.appservice.get_petition(this.state.selectedPetition.id_pet).then(data => {
            if (data[0] !== null && data[0] !== undefined) {
                this.setState({ selectedPetition: data[0], showindicator: false });
            }
        });
    }

    get_liste_petition() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_petitions_actifs().then(data => this.setState({ liste_petition: data, showindicator: false }));
    }

    signer() {
        if (this.state.nom_sign == "") {
            this.messages.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir votre nom' });
        } else if (this.state.tel_sign == "") {
            this.messages.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir votre numéro de téléphone' });
        } else {
            let data = {
                nom_sign: this.state.nom_sign,
                tel_sign: this.state.tel_sign,
                email_sign: this.state.email_sign,
                cmt_sign: this.state.cmt_sign,
                id_pet: this.state.selectedPetition.id_pet
            }
            this.setState({ showindicator: true });
            this.appservice.add_signature(data)
                .then(reponse => {
                    this.setState({ showindicator: false });
                    if (reponse != undefined && reponse != null) {
                        if (reponse.error == false) {
                            this.messages.show({ severity: 'success', summary: '', detail: 'Votre signature a été prise en compte, merci pour votre confiance !', life: 10000 });
                            this.get_petition();
                        } else {
                            if (reponse.code = 'ER_DUP_ENTRY') {
                                this.messages.show({ severity: 'error', summary: '', detail: 'Vous avez déja signé cette pétition !', life: 10000 });
                            } else {
                                this.messages.show({ severity: 'error', summary: '', detail: 'Echec système, merci de ressayer plus tard !', life: 10000 });
                            }
                        }
                    } else {
                        this.messages.show({ severity: 'error', summary: '', detail: 'Echec système, merci de ressayer plus tard !', life: 10000 });
                    }
                });
        }
    }

    renderGridItem(data) {
        let contenu = data.lib_pet;
        if (contenu !== null && contenu !== undefined) {
            contenu = contenu.substring(0, 120) + ' ...';
        }
        const header = (
            <img src={`assets/layout/images/${data.logo_com}`} alt="diamond-layout" style={{ height: '90px', width: '100%' }} />
        );
        const footer = (
            <div >
                <ProgressBar value={data.nb_sign} displayValueTemplate={(value) => {
                    return (
                        <React.Fragment>
                            {value}/<b>{this.appservice.formaterValueSep(parseInt(data.target_pet))} SIGNATURES</b>
                        </React.Fragment>
                    );
                }} />
            </div>
        );
        return (
            <div style={{ padding: '.5em', marginBottom: 10 }} className="p-col-12 p-md-4">
                <a onClick={() => {
                    this.setState({ selectedPetition: data, page: 'detail' }, () => {
                        //window.location = '#/petitions';
                    })
                }}>
                    <Card style={{ width: '97%' }} className="ui-card-shadow" footer={footer} header={header}>
                        <div style={{ color: '#9B9898', fontSize: '14px', fontWeight: 'bold', marginTop: -15, fontFamily: 'Arial' }}> {data.date_pet}</div>
                        <div style={{ color: '#000', fontSize: '15px', fontWeight: 'bold', height: '70px', fontFamily: 'Arial' }}> {contenu}</div>
                        <div style={{ height: '60px', fontFamily: 'Arial' }}><label style={{ color: '#be342d', fontSize: '14px', marginTop: 15, fontFamily: 'Arial' }}>{data.lib_com}</label></div>
                    </Card>
                </a>
            </div>
        );

    }

    enreg_petition() {
        if (this.state.id_com == undefined || this.state.id_com == "" || this.state.desc_pet == "" || this.state.lib_pet == "" || this.state.nom_pet == "" || this.state.tel_pet == "") {
            this.messages.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires !', life: 10000 });
        } else {
            var today = new Date();
            var end = new Date();
            end.setDate(today.getDate() + 180);
            let data =
            {
                'lib_pet': this.state.lib_pet,
                'nom_pet': this.state.nom_pet,
                'tel_pet': this.state.tel_pet,
                'email_pet': this.state.email_pet,
                'id_com': this.state.id_com,
                'desc_pet': this.state.desc_pet,
                'target_pet': 100000
            };
            this.setState({ showindicator: true });
            this.appservice.enreg_petition(data).then(reponse => {
                this.setState({ showindicator: false });
                if (reponse != undefined && reponse != null) {
                    if (reponse.error == false) {
                        this.messages.show({ severity: 'success', summary: '', detail: "Votre pétion a été soumise, elle fera l'objet d'une validation, merci pour votre confiance !", life: 10000 });
                        this.setState({ lib_pet: '' });
                        this.setState({ desc_pet: '' });
                        this.setState({ tel_pet: '' });
                        this.setState({ email_pet: '' });
                        this.get_liste_petition();
                    } else {
                        if (reponse.code = 'ER_DUP_ENTRY') {
                            this.messages.show({ severity: 'error', summary: '', detail: 'Cette pétition existe déja !', life: 10000 });
                        } else {
                            this.messages.show({ severity: 'error', summary: '', detail: 'Echec système, merci de ressayer plus tard !', life: 10000 });
                        }
                    }
                } else {
                    this.messages.show({ severity: 'error', summary: '', detail: 'Echec système, merci de ressayer plus tard !', life: 10000 });
                }
            });
        }
    }


    itemTemplate(data) {
        if (!data) {
            return;
        }
        return this.renderGridItem(data);
    }

    get_liste_commission() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_commission().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.id_com, label: x.lib_com }
                });
                this.setState({ liste_com: liste });
            }
        });
    }

    render() {
        const headerPetition = <Button style={{ marginTop: 10 }} label="Déposer une pétition" icon="pi pi-plus" onClick={(e) => this.setState({ page: 'new' })} className="p-button-info" />

        if (this.state.page == 'liste') {
            return (
                <div >
                    {this.state.liste_petition !== undefined ? <DataView header={headerPetition} value={this.state.liste_petition} emptyMessage="Aucune pétition en cours" layout='grid' itemTemplate={this.itemTemplate} /> : null}

                    <br />
                    <center>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                </div>
            );
        } else if (this.state.page == 'new') {
            return (
                <Panel style={{ marginTop: 10 }}>
                    <Button style={{ marginBottom: 10 }} label="Retour aux pétitions" icon="pi pi-list" onClick={(e) => this.setState({ page: 'liste' }, () => {
                        this.get_liste_petition();
                    })} className="p-button-info" />
                    <div style={{color: '#be342d', fontSize: '24px', fontWeight: 'bold' }}> Nouvelle pétition</div>
                    <div className="p-col-12">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <InputText placeholder="Nom et Prénoms *" value={this.state.nom_pet} onChange={(e) => this.setState({ nom_pet: e.target.value })} style={{ width: '85%' }} required />
                            </div>
                            <div className="p-col-12">
                                <InputText placeholder="Téléphone *" value={this.state.tel_pet} onChange={(e) => this.setState({ tel_pet: e.target.value })} required style={{ width: '85%' }} />
                            </div>
                            <div className="p-col-12">
                                <InputText placeholder="Email" value={this.state.email_pet} onChange={(e) => this.setState({ email_pet: e.target.value })} required style={{ width: '85%' }} />
                            </div>
                            <div className="p-col-12">
                                <InputText placeholder="Titre pétition *" value={this.state.lib_pet} onChange={(e) => this.setState({ lib_pet: e.target.value })} style={{ width: '85%' }} required />
                            </div>
                            <div className="p-col-12">
                                <Dropdown placeholder="Commission *" value={this.state.id_com} options={this.state.liste_com} onChange={(e) => this.setState({ id_com: e.target.value })} autoWidth={false} style={{ width: '85%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                            </div>
                            <div className="p-col-12">
                                <InputTextarea placeholder="Contenu pétition *" value={this.state.desc_pet} onChange={(e) => this.setState({ desc_pet: e.target.value })} rows={10} cols={60} />
                            </div>
                        </div>
                    </div>
                    <Messages ref={(el) => this.messages = el}></Messages>
                    <center>
                        <Button label="Valider " icon="pi pi-check" onClick={(e) => this.enreg_petition()} className="p-button-success" />
                        <br />
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                </Panel>
            )
        } else {
            let prc = (100 * this.state.selectedPetition.nb_sign / this.state.selectedPetition.target_pet).toFixed(4);;
            return (
                <div className="p-col-12">
                    <Button style={{ marginBottom: 10 }} label="Retour aux pétitions" icon="pi pi-list" onClick={(e) => this.setState({ page: 'liste' }, () => {
                        this.get_liste_petition();
                    })} className="p-button-info" />
                    <div className="p-grid" style={{ backgroundColor: '#eeeeee' }}>
                        <div className="p-col-12 p-md-8">
                            <div style={{ color: '#000', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Arial' }}> {this.state.selectedPetition.lib_pet}</div>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <div style={{ width: '100%', backgroundColor: '#e8dbc5', padding: 10, borderRadius: 5 }}>
                                <p>
                                    <label style={{ marginBottom: '1em', height: '80px', fontSize: 22, fontWeight: 'bold', fontFamily: 'Arial' }}>Date limite de recueil des signatures</label>
                                    <br />
                                    <label style={{ fontFamily: 'Arial', fontSize: 16, color: '#000' }}>{this.state.selectedPetition.end_pet}</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-8">
                            <p>
                                <a target="_blank" style={{ color: '#be342d', fontSize: 20, fontWeight: 'bold', fontFamily: 'Arial' }} href={this.state.selectedPetition.url_com}>{this.state.selectedPetition.lib_com}</a>
                                <br />
                                <span style={{ color: '#000', fontSize: '16px', lineHeight: 1.8, fontFamily: 'Arial' }}>
                                    {this.state.selectedPetition.desc_pet}
                                </span>
                            </p>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <Panel style={{ marginTop: 10 }}>
                                <center>
                                    <label style={{ fontWeight: 'bold', fontSize: 36, color: '#000', fontFamily: 'Arial' }}>{this.appservice.formaterValueSep(this.state.selectedPetition.nb_sign)}</label>/<label style={{ fontWeight: 'bold', fontSize: 18 }}>{this.appservice.formaterValueSep(this.state.selectedPetition.target_pet)}</label>
                                    <br />
                                    <label style={{ fontWeight: 'bold', fontSize: 18, color: '#000', fontFamily: 'Arial' }}>SIGNATURES</label>
                                </center>
                                <ProgressBar style={{ marginTop: 10 }} value={this.state.selectedPetition.nb_sign} displayValueTemplate={(value) => {
                                    return (
                                        <React.Fragment>
                                            <label style={{ fontSize: 16, color: 'green' }}>{prc} %</label>
                                        </React.Fragment>
                                    );
                                }} />
                                <p style={{ marginTop: 10 }}>
                                    <label style={{ fontSize: 16, color: '#000', fontFamily: 'Arial' }}>Pour signer cette pétition merci de renseigner le formulaire ci-dessous</label>
                                </p>
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <InputText placeholder="Nom et Prénoms *" value={this.state.nom_sign} onChange={(e) => this.setState({ nom_sign: e.target.value })} style={{ width: '100%' }} required />
                                        </div>
                                        <div className="p-col-12">
                                            <InputText placeholder="Téléphone *" value={this.state.tel_sign} onChange={(e) => this.setState({ tel_sign: e.target.value })} required style={{ width: '100%' }} />
                                        </div>
                                        <div className="p-col-12">
                                            <InputText placeholder="Email" value={this.state.email_sign} onChange={(e) => this.setState({ email_sign: e.target.value })} required style={{ width: '100%' }} />
                                        </div>
                                        <div className="p-col-12">
                                            <InputTextarea placeholder="Commentaire" id="name" value={this.state.cmt_sign} onChange={(e) => this.setState({ cmt_sign: e.target.value })} rows={3} />
                                        </div>
                                    </div>
                                </div>
                                <Messages ref={(el) => this.messages = el}></Messages>
                                <center>
                                    <Button label="Signer la pétition" icon="pi pi-check" onClick={(e) => this.signer()} className="p-button-success" />
                                    <br />
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>
                                <p style={{ marginTop: 15 }}>
                                    <label style={{ fontSize: 16, color: '#000', fontFamily: 'Arial' }}>Voulez-vous déposer votre pétition ? </label>
                                    <br />
                                    <center>
                                        <Button style={{ marginTop: 10 }} label="Déposer ma pétition" icon="pi pi-plus" onClick={(e) => this.setState({ page: 'new' })} className="p-button-info" />
                                    </center>
                                </p>
                            </Panel>
                        </div>
                    </div>
                </div>
            );
        }

    }
}
