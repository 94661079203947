import React, { useState, useEffect, useRef, Component } from 'react';
import classNames from 'classnames';
import AppBreadcrumb from './AppBreadcrumb';
import Appservice from './service/Appservice';

const AppTopbar = (props) => {

    const appservice = new Appservice();
    var userconnected=appservice.get_userconnected();
    

    var nom_user='';

    if(userconnected!==null && userconnected!==undefined){
        nom_user=userconnected.nom_user;
    }

    const notificationsItemClassName = classNames('notifications-item', { 'active-menuitem': props.topbarNotificationMenuActive });
    const profileItemClassName = classNames('profile-item', { 'active-menuitem fadeInDown': props.topbarUserMenuActive });

    return (
        <div className="layout-topbar">
            <div className="topbar-left">
                <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-left"></i>
                </button>
                <span className="topbar-separator"></span>

                <div className="layout-breadcrumb viewname" style={{ textTransform: 'uppercase' }}>
                    <AppBreadcrumb routers={props.routers} />
                </div>

                <img id="logo-mobile" className="mobile-logo" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" />
            </div>

            <div className="topbar-right">
                <ul className="topbar-menu">

                    <li className={profileItemClassName}>
                        <button type="button" className="p-link" onClick={props.onTopbarUserMenu}>
                            <img src="assets/demo/images/avatar/profile.png" alt="diamond-layout" className="profile-image" />
                            <span className="profile-name">{nom_user}</span>
                        </button>
                        <ul className="profile-menu fade-in-up">
                            <li>
                                <button type="button" className="p-link" >
                                    <i className="pi pi-user"></i>
                                    <span>Mon profil</span>
                                </button>
                            </li>

                            <li>
                                <button type="button" className="p-link" onClick={(e) => {
                                    localStorage.removeItem('userconnected');
                                    window.location = "#/login";
                                }}>
                                    <i className="pi pi-power-off"></i>
                                    <span>Déconnexion</span>
                                </button>
                            </li>
                        </ul>
                    </li>


                </ul>
            </div>
        </div>
    );
}

export default AppTopbar;
