import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import 'moment/locale/fr';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';

/*
import { FullCalendar } from 'primereact/fullcalendar';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { Calendar as PRCalendar } from 'primereact/calendar';
*/
import Appservice from '../service/Appservice';

export const Chronogramme = () => {

    const [eventDialog, setEventDialog] = useState(false);
    const [clickedEvent, setClickedEvent] = useState(null);
    const [changedEvent, setChangedEvent] = useState({ title: '', start: null, end: null, url: '' });
    const [events, setEvents] = useState(null);
    const [liste_chrono, setListe_chrono] = useState(null);

    /*
      const options = {
          plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
          defaultDate: new Date(),
          header: {
              left: 'prev,next',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
          },
          editable: true,
          eventClick: (e) => {
              let cmt_chrono=e.event.extendedProps.cmt_chrono;
              const { title, start, end } = e.event;
              setEventDialog(true);
              setClickedEvent(e.event);
              setChangedEvent({ title, start, end, cmt_chrono });
          }
      };
  
      */

    const eventService = new Appservice();




    useEffect(() => {
        eventService.get_liste_chronogramme().then(data => {
            setEvents(data);
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return x.jour
                });

                let liste_jour = [...new Set(liste)];
                let l = [];
                var i;



                for (i = 0; i < liste_jour.length; i++) {
                    l.push({
                        jour: liste_jour[i],
                        frjour: moment(liste_jour[i], 'YYYY-MM-DD').format('DD MMMM YYYY').toUpperCase(),
                        liste_emp: data.filter(function (el) {
                            return el.jour.toLowerCase() == liste_jour[i]
                        })
                    })
                }
                setListe_chrono(l);
            }
        });
    }, [])

    const save = () => {
        setEventDialog(false);
        clickedEvent.setProp('title', changedEvent.title)
        clickedEvent.setStart(changedEvent.start)
        clickedEvent.setEnd(changedEvent.end)
        clickedEvent.setAllDay(changedEvent.allDay)
        setChangedEvent({ title: '', start: null, end: '', allDay: null });
    };


    const renderListItem = (data) => {
        return (
            <div className="p-col-12">
                <div className="p-d-flex p-p-2 ">
                    <label className="p-mr-2" style={{ fontSize: '15px', color: '#000', fontFamily: 'Arial', lineHeight: 1.5 }}>{data.heure}</label>
                    <div className="p-ml-auto" style={{ fontSize: '15px', color: '#999', fontFamily: 'Arial' }} >{data.title}</div>
                </div>
            </div>
        );
    }

    const itemTemplate = (data) => {
        if (!data) {
            return;
        }
        return renderListItem(data);
    }

    const footer = (
        <>
            {/* <Button type="button" label="Save" icon="pi pi-check" className="p-button-text" onClick={save} />
            <Button type="button" label="Reset" icon="pi pi-refresh" className="p-button-text" onClick={reset} /> */}
        </>
    );

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    {liste_chrono !== null && liste_chrono !== undefined && liste_chrono.length > 0 ?
                        <>
                            {liste_chrono.map(emp => (
                                <Panel header={emp.frjour}>
                                    {emp.liste_emp.length > 0 ?
                                        <>
                                            {emp.liste_emp.map(data => (
                                                <div className="p-grid" style={{borderBottom: "1px solid #f7f7f7"}}>
                                                    <label className="p-col-2" style={{ fontSize: '20px', color: '#be342d', fontFamily: 'Arial',fontWeight: 'bold'}}>{data.heure}</label>
                                                    <div className="p-col-10" style={{ fontSize: '16px', color: '#000', fontFamily: 'Arial', lineHeight: 1.5 }} >{data.title}</div>
                                                </div>

                                            ))}
                                        </>
                                        : null}

                                </Panel>

                            ))}
                        </>
                        :
                        null
                    }

                    <Dialog visible={eventDialog && !!clickedEvent} style={{ width: '450px' }} header="Event Details" footer={footer} modal closable onHide={() => setEventDialog(false)}>
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="title">Titre</label>
                                <InputText id="title" value={changedEvent.title} onChange={(e) => setChangedEvent({ ...changedEvent, ...{ title: e.target.value } })} required autoFocus />
                            </div>
                            <div className="p-field">
                                <label htmlFor="start">Début</label>
                                {/* <PRCalendar id="start" value={changedEvent.start} onChange={(e) => setChangedEvent({ ...changedEvent, ...{ start: e.value } })} showTime appendTo={document.body} /> */}
                            </div>
                            <div className="p-field">
                                <label htmlFor="end">Fin</label>
                                {/* <PRCalendar id="end" value={changedEvent.end} onChange={(e) => setChangedEvent({ ...changedEvent, ...{ end: e.value } })} showTime appendTo={document.body} /> */}
                            </div>
                            <div className="p-field">
                                <label htmlFor="end">Détail</label>
                                <InputTextarea id="name" value={changedEvent.cmt_chrono} onChange={(e) => setChangedEvent({ ...changedEvent, ...{ cmt_chrono: e.value } })} rows={3} cols={20} />
                            </div>

                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}
