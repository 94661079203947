import React, { useEffect } from 'react';
import { Route, useLocation, withRouter } from 'react-router-dom';
import App from './App';
import { Login } from './pages/Login';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Access } from './pages/Access';
import { MyPetition } from './pages/MyPetition';
import { MyQuestion } from './pages/MyQuestion';
import { Chronogramme } from './pages/Chronogramme';
const AppWrapper = () => {

    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [location]);


 

    switch (location.pathname) {
        case '/login':
            return <Route path="/login" component={Login} />
        case '/error':
            return <Route path="/error" component={Error} />
        case '/notfound':
            return <Route path="/notfound" component={NotFound} />
        case '/access':
            return <Route path="/access" component={Access} />
        case '/petitions':
            return <Route path="/petitions" component={MyPetition} />
        case '/chronogrammes':
            return <Route path="/chronogrammes" component={Chronogramme} />
        default:
            let path = location.pathname;
            if (path.includes("questions")) {
                return <Route path="/questions/:id" component={MyQuestion} />
            }
            let key = localStorage.getItem('userconnected');
            if (key == null) {
                window.location = "#/login";
                return <Route path="/login" component={Login} />
            } else {
                let user = JSON.parse(key);
                if (user == null && user == undefined) {
                    window.location = "#/login";
                }
                return <App />;
            }
    }
}

export default withRouter(AppWrapper);
