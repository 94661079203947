import React, { useState, useEffect, useRef, Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Appservice from '../service/Appservice';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressBar } from 'primereact/progressbar';

export class FormSignature extends Component {
    constructor() {
        super();
        this.state = {
            liste_signature: [],
            liste_petition: [],
            showindicator: false,
            ridPetition: {value: 'Tous',label: 'Toutes les pétitions'},
            globalFilter: '',
        };
        this.appservice = new Appservice();
    }

    componentDidMount() {
        this.get_liste_signature();
        this.get_liste_petition();
    }

    get_liste_petition() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_petition().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.id_pet, label: x.lib_pet }
                });
                this.setState({ liste_petition: liste });
            }
        });
    }

    get_liste_signature() {
        this.setState({ showindicator: true });
        let rid='Tous';
        if(this.state.ridPetition!==null && this.state.ridPetition!==undefined && this.state.ridPetition.value!=='Tous'){
            rid=this.state.ridPetition;
        }
        this.appservice.get_liste_signature_by_petition(rid).then(data => this.setState({liste_signature: data, showindicator: false }));
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
               <Dropdown value={this.state.ridPetition} options={this.state.liste_petition} placeholder="Sélectioner une pétition" onChange={(e) => this.setState({ ridPetition: e.target.value },()=>{
                   this.get_liste_signature();
               })} autoWidth={false} style={{ width: '250px' }} filter={true} filterBy='label' filterMatchMode='contains' />
            </React.Fragment>
        )
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }



    render() {

        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={(el) => this.toast = el} />
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_signature} 
                            rowHover paginator rows={20} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            emptyMessage="No data found"  style={{ width: '100%' }}>
                            <Column field="date_sign" header="Date" style={{ width: '8%' }} sortable />
                            <Column field="lib_pet" header="Désignation" style={{ width: '20%' }} sortable />
                            <Column field="end_pet" header="Fin" style={{ width: '8%' }} sortable />
                            <Column field="etat_pet" header="Etat" style={{ width: '10%' }} sortable />
                            <Column field="cmt_sign" header="Commentaire" style={{ width: '39' }} sortable />
                            <Column field="nom_sign" header="Signateur" body={(rowData, column) => {
                                return <div>
                                    <span style={{ fontWeight: 'bold' }}>{rowData.nom_sign}</span><br />
                                    <span>{rowData.tel_sign}</span>
                                </div>
                            }} style={{ width: '15%' }} sortable />
                        </DataTable>

                    </div>
                </div>
            </div>
        );
    }
}
