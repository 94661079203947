import React, { useState, useEffect, useRef, Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Appservice from '../service/Appservice';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';


export class FormQuest extends Component {
    constructor() {
        super();
        this.state = {
            selectedQuest: null,
            liste_question: [],
            liste_question_filtre: [],
            modif_quest: false,
            showindicator: false,
            visible_question: false,
            globalFilter: '',
            visible_dlg_quest: false,
            lib_quest: '',
            contenu_quest: '',
            rep_quest: '',
        };
        this.edit_quest = this.edit_quest.bind(this);
        this.appservice = new Appservice();
    }

    componentDidMount() {
        this.get_liste_question();
    }

    get_liste_question() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_question().then(data => this.setState({ liste_question: data,liste_question_filtre: data, showindicator: false }));
    }

    annuler_quest() {
        this.setState({ lib_quest: '' });
        this.setState({ contenu_quest: '' });
        this.setState({ rep_quest: '' });
        this.setState({ modif_quest: false });
    }

    enreg_quest() {
        if (this.state.contenu_quest == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir le contenu de la question' });
        } else {
            let data = this.state.selectedQuest;
            data.rep_quest = this.state.rep_quest;
            data.lib_quest = this.state.lib_quest;
            data.contenu_quest = this.state.contenu_quest;
            this.setState({ showindicator: true });
            this.appservice.update_question(data, 'REP').then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    this.get_liste_question();
                }
            });
        }
    }

    update_etat_quest(data) {
        if (data != null && data != undefined) {
            if (data.etat_quest == 'En attente') {
                data.etat_quest = 'Validé';
            } else if (data.etat_quest == 'Validé') {
                data.etat_quest = 'Invalidé';
            } else if (data.etat_quest == 'Invalidé') {
                data.etat_quest = 'Validé';
            }
            this.setState({ showindicator: true });
            this.appservice.update_question(data, 'VAL').then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    this.get_liste_question();
                }
            });
        }
    }

    edit_quest(data) {
        if (data != null && data != undefined) {
            this.setState({ rep_quest: data.rep_quest });
            this.setState({ contenu_quest: data.contenu_quest });
            this.setState({ modif_quest: true });
            this.setState({ selectedQuest: data });
            this.setState({ visible_dlg_quest: true });
        }
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }

    filtrer_quest() {
        let liste = this.state.liste_question;
        let filtre=this.state.globalFilter.toLowerCase();
        if (filtre !== '' && filtre !== undefined) {
            liste = this.state.liste_question.filter(function (el) {
                let val=el.id_depute+' '+el.nom_depute+' '+el.circonscription_depute;
                val=val.toLowerCase();
                return val.includes(filtre);
            });
        }
        this.setState({ liste_question_filtre: liste });
    }

    conf_delete_question(data) {
        this.setState({ visible_question: true });
        this.setState({ selectedQuest: data });
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-input-icon-right">
                    <InputText style={{ width: 200 }}  onChange={(e) => this.setState({globalFilter: e.target.value },()=>{
                        this.filtrer_quest();
                    })} type="text" placeholder="Rechercher" />
                    <i className="pi pi-search" />
                </span>
            </React.Fragment>
        )
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    leftToolbarTemplate_Contact = () => {
        return (
            <React.Fragment>
                <Dropdown value={this.state.id_user} options={this.state.liste_user} placeholder="Sélectioner un contact" onChange={(e) => this.setState({ id_user: e.target.value })} autoWidth={false} style={{ width: '300px' }} filter={true} filterBy='label' filterMatchMode='contains' />
                <Button label="Ajouter" disabled={this.state.id_user == null || this.state.id_user == undefined || this.state.id_user == ''} icon="pi pi-plus" className="p-button-info" onClick={(e) => this.enreg_contacts()} />
            </React.Fragment>
        )
    }

    resultat(rep) {
        if (rep.error == false) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.message });
            this.annuler_quest();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
        }
        this.setState({ showindicator: false });
    }


    render() {

        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">

                        <Toast ref={(el) => this.toast = el} />
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_question_filtre} selection={this.state.selectedQuest}
                            rowHover paginator rows={20} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            onSelectionChange={(e) => this.setState({ selectedQuest: e.value })}
                            emptyMessage="No data found" selectionMode="single" onRowClick={e => this.setState({ selectedQuest: e.value })}
                            style={{ width: '100%' }}
                            onRowSelect={e => this.setState({ selectedQuest: e.value })}>
                            <Column field="date_quest" header="Date" style={{ width: '8%' }} sortable />
                            <Column field="contenu_quest" header="Contenu question" style={{ width: '26%' }} />
                            <Column field="etat_quest" header="Etat" style={{ width: '8%' }} sortable />
                            <Column field="nom_depute" header="Député" body={(rowData, column) => {
                                return <div>
                                    <span style={{ fontWeight: 'bold',color: '#000' }}>{rowData.nom_depute}</span>
                                </div>
                            }} style={{ width: '16%' }} sortable />
                            <Column field="nom_quest" header="Demandeur" body={(rowData, column) => {
                                return <div>
                                    <span style={{ fontWeight: 'bold' }}>{rowData.nom_quest}</span><br />
                                    <span>{rowData.tel_quest}</span>
                                </div>
                            }} style={{ width: '13%' }} sortable />
                            <Column field="circonscription_quest" header="Circons." style={{ width: '10%' }} sortable />
                            <Column field="nb_like_quest" header="Like" style={{ width: '7%' }} body={(rowData, column) => {
                                return <div>
                                    <span style={{ fontWeight: 'bold', color: 'green' }}>{rowData.nb_like_quest}</span>
                                </div>
                            }} sortable />
                            <Column field="nb_unlike_quest" header="Unlike" style={{ width: '8%' }} body={(rowData, column) => {
                                return <div>
                                    <span style={{ fontWeight: 'bold', color: 'red' }}>{rowData.nb_unlike_quest}</span>
                                </div>
                            }} sortable />
                            <Column body={(rowData, column) => {
                                let cl = "p-button-warning";
                                if (rowData.etat_quest == 'Validé') {
                                    cl = "p-button-success";
                                } else if (rowData.etat_quest == 'Invalidé') {
                                    cl = "p-button-danger";
                                }
                                return <div className="p-grid" style={{ width: 120 }}>
                                    <div className="p-col"><Button onClick={(e) => this.edit_quest(rowData)} className="p-button-info" style={{ width: 30, height: 30 }} icon="pi pi-pencil" /></div>
                                    <div className="p-col"><Button onClick={(e) => this.update_etat_quest(rowData)} className={cl} style={{ width: 30, height: 30, marginLeft: -15 }} icon="pi pi-check" /></div>
                                </div>
                            }} style={{ width: '8%' }} header="Action" />
                        </DataTable>

                        <Dialog visible={this.state.visible_dlg_quest} style={{ width: '450px' }} header="Reponse question" modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_quest: false })}>
                           
                            <div className="p-field">
                                <label htmlFor="name">Question *</label>
                                <InputTextarea id="name" value={this.state.contenu_quest} onChange={(e) => this.setState({ contenu_quest: e.target.value })} rows={5} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="name">Reponse *</label>
                                <InputTextarea id="name" value={this.state.rep_quest} onChange={(e) => this.setState({ rep_quest: e.target.value })} rows={5}  />
                            </div>
                            <center>
                                <>
                                    <Button label="Annuler" icon="pi pi-times" className="p-button-text" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_quest: false })} />
                                    <Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_quest()} style={{ width: 120 }} />
                                </>
                                <br/>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                        </Dialog>

                    </div>
                </div>
            </div>
        );
    }
}
