import Numeral from 'numeral';
export default class Appservice {
    constructor() {
        this.state = {
            //wsurl: 'http://localhost:5000/api_assnat',
            wsurl: 'https://apiassnat.assembleeguinee.org/api_assnat',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
           /*  userconnected: {
               
                "id_user": 1,
                "login_user": "admin",
                "nom_user": "Issouf KEITA",
                "email_user": "issouff@yahoo.fr",
                "tel_user": "628458563",
                "profil_user": "Administrateur",
                "actif_user": "Oui",
                "date_enreg_user": "2020-12-31T16:39:46.000Z"
            } */
        };
    }

    wsurl() {
        return this.state.wsurl;
    }

    formaterValueSep(value) {
        return Numeral(parseFloat(value)).format('0,0');
    }

    get_userconnected() {
        return this.state.userconnected;
    }


get_liste_circonscription(){
    return [
        { label: 'BEYLA', value: 'BEYLA' },
        { label: 'BOFFA', value: 'BOFFA' },
        { label: 'BOKE', value: 'BOKE' },
        { label: 'COYAH', value: 'COYAH' },
        { label: 'DABOLA', value: 'DABOLA' },
        { label: 'DALABA', value: 'DALABA' },
        { label: 'DINGUIRAYE', value: 'DINGUIRAYE' },
        { label: 'DIXINN', value: 'DIXINN' },
        { label: 'DUBREKA', value: 'DUBREKA' },
        { label: 'FARANAH', value: 'FARANAH' },
        { label: 'FORECARIAH', value: 'FORECARIAH' },
        { label: 'FRIA', value: 'FRIA' },
        { label: 'GAOUAL', value: 'GAOUAL' },
        { label: 'GUECKEDOU', value: 'GUECKEDOU' },
        { label: 'KALOUM', value: 'KALOUM' },
        { label: 'KANKAN', value: 'KANKAN' },
        { label: 'KEROUANE', value: 'KEROUANE' },
        { label: 'KINDIA', value: 'KINDIA' },
        { label: 'KISSIDOUGOU', value: 'KISSIDOUGOU' },
        { label: 'KOUBIA', value: 'KOUBIA' },
        { label: 'KOUNDARA', value: 'KOUNDARA' },
        { label: 'KOUROUSSA', value: 'KOUROUSSA' },
        { label: 'LABE', value: 'LABE' },
        { label: 'LELOUMA', value: 'LELOUMA' },
        { label: 'LOLA', value: 'LOLA' },
        { label: 'MACENTA', value: 'MACENTA' },
        { label: 'MALI', value: 'MALI' },
        { label: 'MAMOU', value: 'MAMOU' },
        { label: 'MANDIANA', value: 'MANDIANA' },
        { label: 'MATAM', value: 'MATAM' },
        { label: 'MATOTO', value: 'MATOTO' },
        { label: "N'ZEREKORE",value: "N'ZEREKORE"},
        { label: 'PITA', value: 'PITA' },
        { label: 'RATOMA', value: 'RATOMA' },
        { label: 'SIGUIRI', value: 'SIGUIRI' },
        { label: 'TELIMELE', value: 'TELIMELE' },
        { label: 'TOUGUE', value: 'TOUGUE' },
        { label: 'YOMOU', value: 'YOMOU' },
        { label: 'ETRANGER', value: 'ETRANGER' },
        { label: 'LISTE NATIONALE', value: 'LISTE NATIONALE' }
    ]
}
getDashboard(url) {
    var lien = this.wsurl() + '/etats/'+url;
    if(url=='findQuestJourStat' || url=='findQuestJourListe' ||url=='findQuestStat'){
        lien = this.wsurl() + '/etats/'+url+'/'+this.state.userconnected.id_user;
    }
    return fetch(lien, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            return data;
        }
        )
        .catch((error) => {
            return error
        })
}

enreg_chronogramme(data) {
    var lien = '';
    var methode = 'POST';
    if (data.id == 0 || data.id==undefined) {
        lien = this.wsurl() + '/chronogramme/';
    } else {
        methode = 'PUT';
        lien = this.wsurl() + '/chronogramme/' + data.id;
    }
    return fetch(lien, {
        method: methode,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            return data;
        }
        )
        .catch((error) => {
            return error
        })
}

get_liste_chronogramme() {
    var lien = this.wsurl() + '/chronogramme';
    return fetch(lien, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            return data;
        }
        )
        .catch((error) => {
            return error
        })
}

delete_chronogramme(data) {
    var lien = this.wsurl() + '/chronogramme/'+data.id;
    var methode = 'DELETE';
    return fetch(lien, {
        method: methode,
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            return data;
        }
        )
        .catch((error) => {
            return error
        })
}




    enreg_commission(data) {
        var lien = '';
        var methode = 'POST';
        if (data.id_com == 0 || data.id_com==undefined) {
            lien = this.wsurl() + '/commission/';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/commission/' + data.id_com;
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_liste_commission() {
        var lien = this.wsurl() + '/commission';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    delete_commission(data) {
        var lien = this.wsurl() + '/commission/'+data.id_com;
        var methode = 'DELETE';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }




    add_question(data) {
        var lien = '';
        var methode = 'POST';
        lien = this.wsurl() + '/question/';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    update_question(data,action) {
        var lien = '';
        var methode = 'PUT';
        if (action == 'REP') {
            lien = this.wsurl() + '/question/reponse/' + data.id_quest;
            data.id_user_rep=this.state.userconnected.id_user;
        }else if (action == 'VAL') {
            lien = this.wsurl() + '/question/majetat/' + data.id_quest;
            data.id_user_val=this.state.userconnected.id_user;
        } else if (action == 'LIKE') {
            lien = this.wsurl() + '/question/like/' + data.id_quest;
        }else if (action == 'UNLIKE') {
            lien = this.wsurl() + '/question/unlike/' + data.id_quest;
        }else{
            lien = this.wsurl() + '/question/update/' + data.id_quest;
            data.id_user_val=this.state.userconnected.id_user;
            if(data.req_quest!==null && data.req_quest!==undefined && data.req_quest!==''){
                data.id_user_rep=this.state.userconnected.id_user;
            }
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }


    get_liste_question() {
        var lien = this.wsurl() + '/question/all/'+this.state.userconnected.id_user;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_liste_question_by_depute(id_depute) {
        var lien = this.wsurl() + '/question/'+id_depute;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_liste_question_by_depute_valides(id_depute) {
        var lien = this.wsurl() + '/question/valides/'+id_depute;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }


    add_signature(data) {
        var lien = '';
        var methode = 'POST';
        lien = this.wsurl() + '/signature/';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_liste_signature_by_petition(id_pet) {
        var lien = this.wsurl() + '/signature/'+id_pet;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }


    enreg_depute(data) {
        var lien = '';
        var methode = 'POST';
        if (data.id_depute == 0 || data.id_depute==undefined) {
            lien = this.wsurl() + '/depute/';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/depute/' + data.id_depute;
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_liste_depute() {
        var lien = this.wsurl() + '/depute/0';
        if(this.state.userconnected!==null && this.state.userconnected!==undefined){
             lien = this.wsurl() + '/depute/'+this.state.userconnected.id_user;
        }
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    delete_depute(data) {
        var lien = this.wsurl() + '/depute/'+data.id_depute;
        var methode = 'DELETE';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    
    enreg_petition(data) {
        var lien = '';
        var methode = 'POST';
        if (data.id_pet == 0 || data.id_pet==undefined) {
            lien = this.wsurl() + '/petition/';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/petition/' + data.id_pet+'/'+this.state.userconnected.id_user;
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_petition(id) {
        var lien = this.wsurl() + '/petition/findById/'+id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_liste_petitions_actifs() {
        var lien = this.wsurl() + '/petition/actifs';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_liste_petition() {
        var lien = this.wsurl() + '/petition';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    enreg_user(data) {
        var lien = '';
        var methode = 'POST';
        if (data.id_user == 0 || data.id_user==undefined) {
            lien = this.wsurl() + '/utilisateur/';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/utilisateur/' + data.id_user;
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_liste_users() {
        var lien = this.wsurl() + '/utilisateur';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_identification(login,pwd) {
        var lien = this.wsurl() + '/utilisateur/identification?login='+login+'&pwd='+pwd;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }


    delete_user(data) {
        var lien = this.wsurl() + '/utilisateur/'+data.id_user;
        var methode = 'DELETE';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }


    enreg_contacts(data) {
        var lien = '';
        var methode = 'POST';
        lien = this.wsurl() + '/contacts/';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_liste_contacts(id) {
        var lien = this.wsurl() + '/contacts/'+id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    delete_contacts(data) {
        var lien = this.wsurl() + '/contacts/'+data.id_contact;
        var methode = 'DELETE';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }
}