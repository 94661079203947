import React, { useState, useEffect, useRef, Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Appservice from '../service/Appservice';

export class FormCommission extends Component {
    constructor() {
        super();
        this.state = {
            selectedCom: null,
            lib_com: '',
            url_com: '',
            logo_com: '',
            abreviation_com: '',
            liste_com: [],
            modif: false,
            visible: false,
        };
        this.edit_com = this.edit_com.bind(this);
        this.conf_delete_com = this.conf_delete_com.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.appservice = new Appservice();
    }
   
    componentDidMount() {
        this.get_liste_com();
    }

    get_liste_com() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_commission().then(data => this.setState({liste_com: data, showindicator: false }));
    }

    annuler() {
        this.setState({ url_com: '' });
        this.setState({ lib_com: '' });
        this.setState({ abreviation_com: '' });
        this.setState({ modif: false });
    }

    edit_com(data) {
        if(data!==null && data!==undefined){
            this.setState({ url_com: data.url_com});
            this.setState({ logo_com: data.logo_com});
            this.setState({ lib_com: data.lib_com});
            this.setState({ abreviation_com: data.abreviation_com });
            this.setState({ modif: true });
            this.setState({ visible_dlg_com: true });
        }
    }

    enreg_commission() {
        if (this.state.url_com == ""  || this.state.lib_com == "" || this.state.abreviation_com == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                let data =
                {
                    'url_com': this.state.url_com,
                    'lib_com': this.state.lib_com,
                    'logo_com': this.state.logo_com,
                    'abreviation_com': this.state.abreviation_com
                }
                this.appservice.enreg_commission(data).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        this.resultat(reponse);
                        if (reponse.error == false) {
                            this.get_liste_com();
                        }
                    }
                });
            } else {
                let data = this.state.selectedCom;
                data.url_com = this.state.url_com;
                data.lib_com = this.state.lib_com;
                data.logo_com = this.state.logo_com;
                this.appservice.enreg_commission(data).then(reponse => {
                    {
                        if (reponse != undefined && reponse != null) {
                            this.resultat(reponse);
                            if (reponse.error == false) {
                                this.get_liste_com();
                            }
                        }
                    }
                });
            }
        }
    }



    conf_delete_com(data) {
        this.setState({ visible: true });
        this.setState({ selectedCom: data });
    }

    toogleDlg() {
        this.setState({ visible_dlg_com: !this.state.visible_dlg_com });
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nouveau" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.toogleDlg} />
                <Button label="Modifier" disabled={this.state.selectedCom == null} icon="pi pi-pencil" className="p-button-info" onClick={(e) => this.edit_com(this.state.selectedCom)} />
            </React.Fragment>
        )
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    resultat(rep) {
        if (rep.error == false) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.message });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 110 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_com(rowData)} className="p-button-info" style={{ width: 30, height: 30 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_com(rowData)} className="p-button-danger" style={{ width: 30, height: 30,marginLeft:-10 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_com() {
        if (this.state.selectedCom == null || this.state.selectedCom == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.appservice.delete_com(this.state.selectedCom).then(reponse => {
                this.resultat(reponse);
                if (reponse.error == false) {
                    this.get_liste_com();
                }
            });
        }
    }


    render() {
       
        const deleteDialogFooter = (
            <>
                <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={() => {
                    this.setState({ visible: false });
                }} />
                <Button label="Oui" icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_com();
                }} />
            </>
        );

        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">

                        <Toast ref={(el) => this.toast = el} />
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_com}
                            scrollHeight="900px" scrollable={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            dataKey="id_com" rowHover selection={this.state.selectedCom} onSelectionChange={(e) => this.setState({ selectedCom: e.value })}
                            emptyMessage="No data found" selectionMode="single" onRowClick={e => this.setState({ selectedCom: e.value }, () => {
                            })}
                            onRowSelect={e => this.setState({ selectedCom: e.value })}>
                            <Column field="id_com" header="ID"  style={{ width: '5%' }} />
                            <Column field="lib_com" header="Désignation"  style={{ width: '30%' }} />
                            <Column field="url_com" header="URL"  style={{ width: '25%' }} />
                            <Column field="abreviation_com" header="Abréviation" style={{ width: '15%' }} />
                            <Column field="logo_com" header="Logo" style={{ width: '15%' }} />
                            <Column body={this.actionTemplate.bind(this)} style={{ width: '10%' }} header="Action" />
                        </DataTable>


                        <Dialog visible={this.state.visible_dlg_com} style={{ width: '450px' }} header={this.state.modif == true ? "Modification" : "Nouvelle Commission"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_com: false })}>
                           
                            <div className="p-field">
                                <label htmlFor="name">Désignation *</label>
                                <InputText id="name" value={this.state.lib_com} onChange={(e) => this.setState({ lib_com: e.target.value })} required autoFocus />
                            </div>

                            <div className="p-field">
                                <label htmlFor="name">Abréviation *</label>
                                <InputText id="name" value={this.state.abreviation_com} onChange={(e) => this.setState({ abreviation_com: e.target.value })} required autoFocus />
                            </div>

                            <div className="p-field">
                                <label htmlFor="name">URL *</label>
                                <InputText id="name" value={this.state.url_com} onChange={(e) => this.setState({url_com: e.target.value })} required autoFocus />
                            </div>

                            <div className="p-field">
                                <label htmlFor="name">Logo *</label>
                                <InputText id="name" value={this.state.logo_com} onChange={(e) => this.setState({ logo_com: e.target.value })} required autoFocus />
                            </div>
                           

                            <center>
                                <>
                                    <Button label="Annuler" icon="pi pi-times" className="p-button-text" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_com: false })} />
                                    <Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_commission()} style={{ width: 120 }} />
                                </>
                                <br/>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                        </Dialog>

                        <Dialog visible={this.state.visible} style={{ width: '450px' }} header="Confirmation" modal footer={deleteDialogFooter} onHide={(e) => this.setState({ visible: false })}>
                            <div className="p-d-flex p-ai-center p-jc-center">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedCom && <span>Voulez-vous vraiment supprimer <b>{this.state.selectedCom.lib_com}</b>?</span>}
                            </div>
                        </Dialog>

                      
                    </div>
                </div>
            </div>
        );
    }
}
